import { createSlice } from "@reduxjs/toolkit";

import { saveTokens } from "@app/features/auth/auth";
import { createApiAsyncThunk } from "@app/redux/api.thunk";

import { getTenantEng } from "../../tenants/helpers/tenants.helpers";
import { getCurrentUserApi, updateUserApi } from "../api/users.api";
import { USERS_FEATURE_KEY } from "../constants/users.keys";
import { UpdateUserRequestBody, UserState } from "../users";

const initialState: UserState = {
  currentUser: null,
};

const tenant_name_eng = getTenantEng();
export const getCurrentUser = createApiAsyncThunk(
  `${USERS_FEATURE_KEY}/getCurrentUser`,
  () => {
    return getCurrentUserApi(tenant_name_eng);
  }
);

export const updateUser = createApiAsyncThunk(
  `${USERS_FEATURE_KEY}/updateUser`,
  (data: UpdateUserRequestBody) => {
    return updateUserApi({ tenant_name_eng, data });
  }
);

export const usersSlice = createSlice({
  name: USERS_FEATURE_KEY,
  initialState,
  reducers: {
    updateCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: builder => {
    // TODO
    // Get current user

    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(getCurrentUser.rejected, state => {
      state.currentUser = null;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      const { access_token, refresh_token } = action.payload;
      if (access_token && refresh_token) {
        saveTokens({ ...action.payload });
      }
    });
  },
});

export { USERS_FEATURE_KEY, initialState as usersInitialState };
export const { updateCurrentUser } = usersSlice.actions;
export const userReducer = usersSlice.reducer;

import {
  ByMonth,
  FetchAnswerInvoice,
  AnswerInvoice,
  AnswerInvoiceGroup,
} from "@app/features/branches/branches";
import {
  createMonthsFromClosingMonth,
  formatMonthWithYear,
} from "@app/helpers/util.helper";

/**
 * 月ごとの使用量データを作成する
 * @param data
 * @param year
 * @param closingMonth
 * @param unit
 * @returns Invoice[]
 */
export const createInvoice = (
  data: ByMonth[],
  year: number,
  closingMonth: number,
  unit: string
): AnswerInvoice[] => {
  const result: AnswerInvoice[] = [];
  const months = createMonthsFromClosingMonth(closingMonth);
  months.forEach(month => {
    const invoice = data.find(item => item.month === month);
    const monthWithYear = formatMonthWithYear(month, year, closingMonth);
    if (!invoice) {
      result.push({
        month,
        monthWithYear,
        unit,
        usageAmount: null,
        costAmount: null,
      });
    } else {
      result.push({
        month,
        monthWithYear,
        unit,
        usageAmount: invoice.usage_amount as number | null,
        costAmount: invoice.total_amount as number | null,
      });
    }
  });
  return result;
};

/**
 * 月ごとの使用量ブランクデータを作成する
 * @param year
 * @param unit
 * @param closingMonth
 * @returns Invoice[]
 */
export const createBlankInvoice = (
  year: number,
  unit: string,
  closingMonth: number
): AnswerInvoice[] => {
  const result: AnswerInvoice[] = [];
  const months = createMonthsFromClosingMonth(closingMonth);
  months.forEach(month => {
    result.push({
      month,
      monthWithYear: formatMonthWithYear(month, year, closingMonth),
      usageAmount: null,
      costAmount: null,
      unit,
    });
  });
  return result;
};

/**
 * エネルギー種別ごとの使用量データを作成する
 * @param answerInvoices
 * @param fiscalYear
 * @param closingMonth
 * @returns InvoiceGroup[]
 */
export const createInvoiceGroup = (
  answerInvoices: FetchAnswerInvoice[],
  fiscalYear: number,
  closingMonth: number
): AnswerInvoiceGroup[] => {
  const result: AnswerInvoiceGroup[] = [];
  answerInvoices.forEach(item => {
    const { unit } = item;
    const invoice: AnswerInvoiceGroup = {
      energyTypeMasterId: item.energy_type_master_id as number,
      name: item.energy_name as string,
      unit,
      answerInvoices: item.by_month
        ? createInvoice(item.by_month, fiscalYear, closingMonth, unit)
        : createBlankInvoice(fiscalYear, unit, closingMonth),
    };
    result.push(invoice);
  });
  return result;
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { createMonthsFromClosingMonth } from "@app/helpers/util.helper";
import { createApiAsyncThunk } from "@app/redux/api.thunk";

import { getTenantEng } from "../../tenants/helpers/tenants.helpers";
import { EMISSION_KEY } from "../constants/emissions.keys";
import {
  co2EmissionApi,
  co2EmissionByBranchApi,
  co2EmissionByMonthApi,
  co2EmissionByScopeApi,
} from "../emissions";
import type {
  Co2EmissionResponseBody,
  Co2EmissionByMonthResponseBody,
  Co2State,
  Co2StateMonthly,
  ListCO2ByMonth,
  EmissionType,
  Co2EmissionRequestParams,
  Co2EmissionByBranchRequestParams,
  Co2EmissionByMonthRequestParams,
  Co2EmissionByScopeRequestParams,
} from "../emissions";

const initialState: Co2State = {
  data: [],
  total: [],
  sum: 0,
  monthData: [],
  monthTotal: [],
  monthSum: 0,
  isLoading: true,
};

const tenant_name_eng = getTenantEng();
export const getCo2Emissions = createApiAsyncThunk(
  `${EMISSION_KEY}/co2_emission`,
  (params: Co2EmissionRequestParams) => {
    return co2EmissionApi(tenant_name_eng, params);
  }
);
export const getCo2EmissionsByMonth = createApiAsyncThunk(
  `${EMISSION_KEY}/co2_emission_by_month`,
  (params: Co2EmissionByMonthRequestParams) => {
    return co2EmissionByMonthApi(tenant_name_eng, params);
  }
);
export const getCo2EmissionsByBranch = createApiAsyncThunk(
  `${EMISSION_KEY}/co2_emission_by_branch`,
  (params: Co2EmissionByBranchRequestParams) => {
    return co2EmissionByBranchApi(tenant_name_eng, params);
  }
);
export const getCo2EmissionsByScope = createApiAsyncThunk(
  `${EMISSION_KEY}/co2_emission_by_scope`,
  (params: Co2EmissionByScopeRequestParams) => {
    return co2EmissionByScopeApi(tenant_name_eng, params);
  }
);

export const emissionSlice = createSlice({
  name: EMISSION_KEY,
  initialState,
  reducers: {
    beginLoading: state => {
      state.isLoading = true;
    },
    endLoading: state => {
      state.isLoading = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCo2Emissions.pending, state => {
      state.data = [];
      state.total = [];
      state.sum = 0;
      state.isLoading = true;
    });
    builder.addCase(getCo2EmissionsByMonth.pending, state => {
      state.data = [];
      state.total = [];
      state.sum = 0;
      state.monthData = [];
      state.monthTotal = [];
      state.monthSum = 0;
      state.isLoading = true;
    });
    builder.addCase(getCo2EmissionsByBranch.pending, state => {
      state.branchRate = undefined;
    });
    builder.addCase(getCo2EmissionsByScope.pending, state => {
      state.scopeRate = undefined;
    });
    builder.addCase(
      getCo2EmissionsByMonth.fulfilled,
      (state, action: PayloadAction<Co2EmissionByMonthResponseBody>) => {
        const { emissions, total, sum } = action.payload;

        // 排出合計が0 または その他以外の分類が存在しない場合はデータ無しとみなす
        // NOTE: 使用量あり & 排出なしをCo2排出量画面で確認することはできない
        // NOTE: 排出が負数になる input は存在しない
        if (!sum || !total.some(value => value.type !== "other")) {
          state.isLoading = false;
          return;
        }

        const groupedEmissions: { [month: string]: EmissionType[] } = {};
        emissions.forEach(emission => {
          const { month } = emission;
          if (!groupedEmissions[month]) {
            groupedEmissions[month] = [];
          }
          groupedEmissions[month].push(emission);
        });

        // 決算月の取得
        const closingMonth = Number(emissions[emissions.length - 1].month);
        const months = createMonthsFromClosingMonth(closingMonth);

        const data: ListCO2ByMonth[] = Array.from(months, (month, index) => {
          const item: ListCO2ByMonth = {
            key: index,
            month: `${month}`,
          };

          const monthEmissions = groupedEmissions[month] || [];
          monthEmissions.forEach(emission => {
            item[emission.type] = emission.tco2;
            item[`${emission.type}Prev`] = emission.prev_tco2;
            item[`${emission.type}GrowthRate`] = emission.growth_rate;
          });

          return item;
        });

        Object.assign<Co2State, Co2StateMonthly>(state, {
          monthData: data,
          monthTotal: total,
          monthSum: sum,
        });
        state.isLoading = false;
      }
    );
    builder.addCase(
      getCo2Emissions.fulfilled,
      (state, action: PayloadAction<Co2EmissionResponseBody>) => {
        const { data, total, sum } = action.payload;
        state.data = data;
        // NOTE: tco2が0のものは除外する
        state.total = total.filter(item => item.tco2 !== 0);
        state.sum = sum;
        state.isLoading = false;
      }
    );
    builder.addCase(getCo2EmissionsByBranch.fulfilled, (state, action) => {
      state.branchRate = action.payload;
    });
    builder.addCase(getCo2EmissionsByScope.fulfilled, (state, action) => {
      state.scopeRate = action.payload;
    });
  },
});

export { EMISSION_KEY, initialState as emissionInitialState };
export const { beginLoading } = emissionSlice.actions;
export const emissionReducer = emissionSlice.reducer;

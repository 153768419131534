import { configureStore } from "@reduxjs/toolkit";

import { DeepPartial } from "@app/types/utils.types";

import rootReducer from "./root-reducer";

export function setupStore(preloadedState?: Partial<RootState>) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== "production",
  });

  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export type PartialState = DeepPartial<RootState>;

export * from "@app/features/slices";
export { resetStore, resetWithoutAuth } from "./root-reducer";

export * from "./hooks";

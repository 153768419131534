import { getTenantEng } from "../../tenants/helpers/tenants.helpers";

const tenant_name_eng = getTenantEng();

export enum RoadmapRoutePaths {
  ROADMAP = "/:tenant_name_eng/roadmap",
}

export const RoadmapPaths = {
  ROADMAP: () => `/${tenant_name_eng}/roadmap`,
};

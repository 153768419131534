/* eslint-disable no-irregular-whitespace */

// eslint-disable-next-line filenames/match-regex
import { Col, Row, Typography, Space, Statistic, TabsProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import cx from "classnames";
import type { EChartsOption, PieSeriesOption } from "echarts";

import { FormatterNumber } from "@app/components/atoms/FormatterNumber/FormatterNumber";
import TCO2eUnit from "@app/components/atoms/TCO2eUnit/TCO2eUnit";
import RenderTableDate from "@app/components/molecules/RenderTableDate/RenderTableDate";
import RenderTableGrowthRate from "@app/components/molecules/RenderTableGrowthRate/RenderTableGrowthRate";
import RenderTableValue from "@app/components/molecules/RenderTableValue/RenderTableValue";
import { formatFloat } from "@app/helpers/util.helper";

import {
  SummaryCo2Type,
  ColorIconType,
  ListCO2ByMonth,
  TotalType,
  LegendDataType,
  SeriesType,
  ColsType,
  AlignType,
  ListCO2ByYear,
  SummaryCo2RateType,
} from "../emissions";

const { Text, Paragraph } = Typography;

export const YEARLY_PERIOD_LENGTH = 10;

export const PERIOD_TAB_KEY = {
  month: "month",
  years: "year",
} as const;

export const PERIOD_TAB_ITEMS: TabsProps["items"] = [
  {
    key: PERIOD_TAB_KEY.month,
    label: "月次",
  },
  {
    key: PERIOD_TAB_KEY.years,
    label: "年次",
  },
];

export const RATE_TYPE = {
  scope: "scope",
  branch: "branch",
} as const;

export const COLOR_ICON: ColorIconType = {
  electric: "bg-purple-dark",
  gas: "bg-purple",
  other: "bg-purple-light",
};

export const CHART_COLOR = ["#8066D5", "#AB91FF", "#C5B2FF"];
export const CHART_COLOR_PREV = ["#C1B7DF", "#E5DCFF", "#F9F5FF"];

export const RATE_COLORS = [
  "#302751",
  "#694AD1",
  "#907CD9",
  "#C3B7EB",
  "#DFD8F5",
  "transparent",
];

export const OPTIONS_SUMMARY_CO2: ColumnsType<SummaryCo2Type> = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (name: string) => <Text className="font-13">{name}</Text>,
  },
  {
    title: "温室効果ガス（合計）",
    dataIndex: "tco2",
    key: "tco2",
    render: (tco2, record) => (
      <Row align="middle" gutter={8} justify="space-between">
        <Col>
          <div className={cx("icon-rect co2-rect m-0")} />
        </Col>
        <Col>
          <Space>
            <Text
              className="font-20 font-weight-bold no-wrap"
              title={`${record.tco2}`}
            >
              <Statistic value={record.tco2 ?? 0} formatter={FormatterNumber} />
            </Text>
            <Text className="font-12 tco2">
              <TCO2eUnit />
            </Text>
          </Space>
        </Col>
      </Row>
    ),
  },
  {
    title: "総合計",
    dataIndex: "sum",
    key: "sum",
    render: (_, record) => (
      <Paragraph className="font-weight-bold">
        <Text title={`${record.sum}`}>
          <Space>
            <Statistic
              className="total-number"
              value={record.sum ?? 0}
              formatter={FormatterNumber}
            />
            <Text className="font-12">
              <TCO2eUnit />
            </Text>
          </Space>
        </Text>
      </Paragraph>
    ),
    onCell: (_, index) => ({
      rowSpan: index === 0 ? 3 : 0,
    }),
  },
];

export const COLUMNS_RATE_SUMMARY: (
  groupName: string
) => ColumnsType<SummaryCo2RateType> = groupName => [
  {
    title: groupName,
    dataIndex: "group",
    key: "group",
    ellipsis: true,
    render: (group: SummaryCo2RateType["group"]) => (
      <Text className="font-13">{group.name}</Text>
    ),
  },
  {
    title: "排出量",
    dataIndex: "tco2_value",
    key: "tco2_value",
    width: "45%",
    render: (value, record, index) => (
      <Row align="middle" gutter={8} justify="space-between">
        <Col flex="none">
          <div
            className={cx("icon-rect m-0")}
            style={{
              backgroundColor: record.isTotal
                ? "transparent"
                : RATE_COLORS[index],
            }}
          />
        </Col>
        <Col flex="auto">
          <Space className="flex-justify-end">
            <Text
              className="font-20 font-weight-bold no-wrap"
              title={`${record.tco2_value}`}
            >
              <Statistic value={value ?? 0} formatter={FormatterNumber} />
            </Text>
            <Text className="font-12 tco2">
              <TCO2eUnit />
            </Text>
          </Space>
        </Col>
      </Row>
    ),
  },
  {
    title: "割合",
    dataIndex: "ratio",
    key: "ratio",
    width: 100,
    render: value => <Text className="font-13">{value}%</Text>,
  },
];

// 年次のリスト表示用オプション
export const OPTION_COLUMN_TABLE = (total: TotalType[]): ColsType[] => {
  const cols: ColsType[] = [
    {
      key: "year",
      title: "",
      dataIndex: "year",
      render: (year: number): JSX.Element => (
        <Text className="font-12" strong>
          {year}
        </Text>
      ),
      width: "7%",
      align: "center",
    },
  ];

  total.forEach((item: TotalType) => {
    const tco2Cell = {
      key: item.type,
      title: item.name,
      dataIndex: item.type,
      align: "center" as AlignType,
      width: "31%",
      render: (value: number) => (
        <RenderTableValue
          value={Number(value)}
          unit={<TCO2eUnit />}
          align="center"
        />
      ),
    };
    cols.push(tco2Cell);
  });
  return cols;
};

// 月次のリスト表示用オプション
export const OPTION_COLUMN_CO2_TABLE = (
  total: TotalType[],
  activeYear: number,
  closingMonth: number
): ColsType[] => {
  const cols = [
    {
      key: "month",
      title: "年月",
      dataIndex: "month",
      render: (month: number) => (
        <RenderTableDate
          month={month}
          year={activeYear}
          closingMonth={closingMonth}
        />
      ),
      width: "13%",
      align: "center" as AlignType,
    },
  ];
  total.forEach((item: TotalType) => {
    const tco2Cell = {
      key: item.type,
      title: item.name,
      dataIndex: item.type,
      align: "center" as AlignType,
      width: "14.5%",
      render: (value: number) => (
        <RenderTableValue value={Number(value)} unit={<TCO2eUnit />} />
      ),
    };
    const growthRateCell = {
      key: `${item.type}GrowthRate`,
      title: "前年同月比",
      dataIndex: `${item.type}GrowthRate`,
      align: "center" as AlignType,
      width: "14.5%",
      render: (value: number | null) => <RenderTableGrowthRate value={value} />,
    };
    cols.push(tco2Cell, growthRateCell);
  });
  return cols;
};

export const OPTION_CHART = (
  listTCo2: ListCO2ByYear[],
  total: TotalType[],
  years: number[]
): EChartsOption => {
  const totalTemp = total || [];
  // テンプレート
  const seriesTemplate = {
    name: "",
    type: "bar" as const,
    stack: "",
    data: [],
    color: "#CCCCCC",
    barWidth: 30,
  };
  // データ値
  const labels: LegendDataType[] = [];
  // ラベル設定
  totalTemp.forEach((totalItem: any) => {
    labels.push({
      name: totalItem.name,
      icon: "roundRect",
    });
  });
  const series: SeriesType[] = [];
  totalTemp.forEach((totalItem: any, i) => {
    // 対象年のデータ
    series.push({
      ...seriesTemplate,
      name: totalItem.name,
      stack: "A",
      color: CHART_COLOR[i],
      data: listTCo2.map((item: any) => item[`${totalItem.type}`] ?? null),
    });
  });
  return {
    legend: {
      data: labels,
      bottom: 10,
    },
    tooltip: {
      trigger: "axis",
      formatter: params => {
        if (!Array.isArray(params)) {
          // NOTE: シリーズは複数存在するため、ここに来ることはない
          return "";
        }

        const formatValue = (v: string) => {
          return `<span style="float: right; margin-left: 20px"><b>${v}</b> t-CO2e</span>`;
        };
        let sum = 0;
        const details = params.map(paramsItem => {
          const { marker, seriesName, value } = paramsItem;
          let v = "-";
          if (typeof value === "number") {
            v = formatFloat(value);
            sum += value; // 年度合計
          }
          return `${marker}${seriesName} ${formatValue(v)}`;
        });

        return `${params.at(0)?.name}年度
          ${formatValue(formatFloat(sum))}
          <hr />
          ${details.join("<br>")}`;
      },
    },
    grid: {
      left: 40,
      right: 70,
      top: 40,
      bottom: 90,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: years,
      offset: 30,
      axisPointer: {
        type: "shadow",
      },
      axisLabel: {
        fontSize: 18,
        color: "#666666",
        formatter: (value: string) => `${value}`,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      name: "[ t-CO{co2|2}e ]",
      nameTextStyle: {
        align: "right",
        padding: [5, 7],
        color: "#999999",
        fontWeight: 400,
        rich: {
          co2: {
            fontSize: 10,
            padding: [10, 0, 0, 0],
            verticalAlign: "bottom",
          },
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#ccc",
          type: [5, 2],
        },
      },
      axisLabel: {
        color: "#999999",
      },
    },
    series,
  };
};

export const OPTION_CHART_BY_MONTH = (
  listTCo2: ListCO2ByMonth[],
  total: TotalType[],
  months: number[]
): EChartsOption => {
  const totalTemp = total || [];
  // テンプレート
  const seriesTemplate = {
    name: "",
    type: "bar" as const,
    stack: "",
    data: [],
    color: "#CCCCCC",
    barWidth: 30,
  };
  // データ値
  const labels: LegendDataType[] = [];
  // ラベル設定
  totalTemp.forEach((totalItem: any) => {
    labels.push({
      name: totalItem.name,
      icon: "roundRect",
    });
  });
  totalTemp.forEach((totalItem: any) => {
    labels.push({
      name: `${totalItem.name}(前年)`,
      icon: "roundRect",
    });
  });
  const series: SeriesType[] = [];
  // グラフには逆順にして渡す
  totalTemp.forEach((totalItem: any, i) => {
    // 昨年のデータ
    series.push({
      ...seriesTemplate,
      name: `${totalItem.name}(前年)`,
      stack: "B",
      data: listTCo2.map((item: any) => item[`${totalItem.type}Prev`] ?? null),
      color: CHART_COLOR_PREV[i],
      barGap: "-40%", // 重ね合わせ
      z: 1,
      zlevel: 1,
      tooltip: {
        show: false,
      },
    });
    // 対象年のデータ
    series.push({
      ...seriesTemplate,
      name: totalItem.name,
      stack: "A",
      color: CHART_COLOR[i],
      data: listTCo2.map((item: any) => item[`${totalItem.type}`] ?? null),
      zlevel: 2,
    });
  });
  return {
    legend: {
      data: labels,
      bottom: 10,
    },
    tooltip: {
      trigger: "axis",
      formatter: params => {
        if (!Array.isArray(params)) {
          // NOTE: シリーズは複数存在するため、ここに来ることはない
          return "";
        }

        const formatValue = (v: string) => {
          return `<span style="float: right; margin-left: 20px"><b>${v}</b> t-CO2e</span>`;
        };
        let sum = 0;
        const details = params.map(paramsItem => {
          const { marker, seriesName, value } = paramsItem;
          let v = "-";
          if (typeof value === "number") {
            v = formatFloat(value);
            sum += value; // 月合計
          }
          return `${marker}${seriesName} ${formatValue(v)}`;
        });

        return `${params.at(0)?.name}月
          ${formatValue(formatFloat(sum))}
          <hr />
          ${details.join("<br>")}`;
      },
    },
    grid: {
      left: 40,
      right: 70,
      top: 40,
      bottom: 90,
      containLabel: true,
    },

    xAxis: {
      type: "category",
      data: months,
      offset: 30,
      axisPointer: {
        type: "shadow",
      },
      axisLabel: {
        fontSize: 18,
        color: "#666666",
        formatter: (value: string) => `${value}月`,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      name: "[ t-CO{co2|2}e ]",
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          color: "#ccc",
          type: [5, 2],
        },
      },
      axisLabel: {
        color: "#999999",
      },
      nameTextStyle: {
        align: "right",
        padding: [5, 7],
        color: "#999999",
        fontWeight: 400,
        rich: {
          co2: {
            fontSize: 10,
            padding: [10, 0, 0, 0],
            verticalAlign: "bottom",
          },
        },
      },
    },
    series,
  };
};

export const RATE_PIE_RAIUS = 100;
export const OPTION_RATE_PIE_CHART = (
  data: SummaryCo2RateType[]
): EChartsOption => {
  const seriesTemplate: PieSeriesOption = {
    type: "pie" as const,
    label: {
      show: false,
    },
    radius: [RATE_PIE_RAIUS / 2 - 5, RATE_PIE_RAIUS],
    height: RATE_PIE_RAIUS * 2 + 8,
    itemStyle: {
      borderColor: "#fff",
      borderWidth: 2,
      color: params => {
        return RATE_COLORS[params.dataIndex];
      },
    },
    data: data.map(d => ({ name: d.group.name, value: d.ratio })),
  };

  return {
    legend: {
      data: data.map(item => ({
        name: item.group.name,
        icon: "roundRect",
      })),
      textStyle: {
        // 5つ目が"その他"以外で、かつ全ての拠点がoverflowする場合は3行になってしまう。最低限の調整
        width: 180,
        overflow: "truncate",
      },
      // NOTE:
      // legendは2行に収まることを前提としたデザイン
      // 万が一3行になった場合も、枠外にoverflowしないようにbottomを指定
      // またbottomのみだと1行の時に間延びするため、4つ以下(必ず2行に収まる)場合topを指定
      top: data.length >= 5 ? undefined : Number(seriesTemplate.height) + 16,
      bottom: 0,
    },
    tooltip: {
      trigger: "item",
      valueFormatter: value => `${value}%`,
    },
    grid: {
      top: 0,
      containLabel: true,
    },
    series: [seriesTemplate],
  };
};

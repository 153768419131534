import { api } from "@app/api/api";
import type { CustomAxiosResponse } from "@app/api/api.types";
import { getTenantEng } from "@app/features/tenants/tenants";

import {
  EnergyTypeListRequestParams,
  EnergyTypeListResponse,
} from "../invoice";
import { AnsweredFiscalYears, ListYears, ReportEndpoints } from "../reports";

const tenant_name_eng = getTenantEng();

export const listFiscalYearApi = () => {
  return api.get<ListYears, CustomAxiosResponse<ListYears>>(
    ReportEndpoints.LIST_FISCAL_YEAR(tenant_name_eng)
  );
};

export const listAnsweredFiscalYearApi = () => {
  return api.get<AnsweredFiscalYears, CustomAxiosResponse<AnsweredFiscalYears>>(
    ReportEndpoints.LIST_ANSERED_FISCAL_YEAR(tenant_name_eng)
  );
};

export const listEnergyTypeWithUsageStatusApi = (
  params: EnergyTypeListRequestParams
) => {
  return api.get<
    EnergyTypeListResponse,
    CustomAxiosResponse<EnergyTypeListResponse>
  >(ReportEndpoints.LIST_ENERGY_TYPE_WITH_USAGE_STATUS(tenant_name_eng), {
    params,
  });
};

import { lazy, Suspense } from "react";

import { RouteObject } from "react-router-dom";

const AccountInformationScreen = lazy(
  () => import("../screens/AccountInformationScreen/AccountInformationScreen")
);
const AccountCompaniesScreen = lazy(
  () => import("../screens/AccountCompaniesScreen/AccountCompaniesScreen")
);

const USERS_ROUTES: RouteObject[] = [
  {
    path: "account/information",
    element: (
      <Suspense fallback="loading please wait...">
        <AccountInformationScreen />
      </Suspense>
    ),
  },
  {
    path: "account/companies",
    element: (
      <Suspense fallback="loading please wait...">
        <AccountCompaniesScreen />
      </Suspense>
    ),
  },
];
export default USERS_ROUTES;

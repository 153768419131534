import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { Answer, AnswersEndpoints, UpdateStatusAnswerType } from "../answers";

export const putAnswerApi = (
  tenant_name_eng: string,
  data: Answer
): Promise<AxiosResponse> => {
  return api.put(AnswersEndpoints.PUT_ANSWER(tenant_name_eng), data);
};

export const updateStatusAnswerApi = (
  tenant_name_eng: string,
  data: UpdateStatusAnswerType
): Promise<AxiosResponse> => {
  return api.patch(
    AnswersEndpoints.UPDATE_STATUS_ANSWER(tenant_name_eng),
    data
  );
};

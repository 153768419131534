import { FC } from "react";

import Text from "antd/es/typography/Text";

import { formatMonthWithYear } from "@app/helpers/util.helper";

interface Props {
  month: number;
  year: number;
  closingMonth: number;
}

const RenderTableDate: FC<Props> = ({ month, year, closingMonth }) => {
  const value = formatMonthWithYear(month, year, closingMonth);
  return (
    <Text strong className="font-12">
      {value}
    </Text>
  );
};

export default RenderTableDate;

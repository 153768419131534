import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  clearCurrentTenantCookie,
  getTenantEng,
} from "@app/features/tenants/tenants";
import { createApiAsyncThunk } from "@app/redux/api.thunk";

import {
  verifyTokenApi,
  forgotPasswordApi,
  loginApi,
  logoutApi,
  resetPasswordApi,
  confirmResetPasswordApi,
  setupPasswordApi,
} from "../api/auth.api";
import { AUTH_FEATURE_KEY } from "../constants/auth.keys";
import { clearTokens, saveTokens } from "../helpers/auth.helpers";
import {
  LoginResponse,
  AuthState,
  LoginRequestBody,
  ForgotPasswordRequestBody,
  ResetPasswordRequestBody,
  SetupPasswordRequestBody,
} from "../types/auth.types";

const initialState: AuthState = {
  user_verify: null,
  isAuthenticated: false,
  loading: false,
};

const tenant_name_eng_tmp = getTenantEng();
export const login = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/login`,
  (data: LoginRequestBody) => {
    return loginApi(tenant_name_eng_tmp, data);
  }
);

export const logoutServer = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/logout`,
  () => {
    return logoutApi(tenant_name_eng_tmp);
  }
);

export const forgotPassword = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/forgotPassword`,
  (data: ForgotPasswordRequestBody) => {
    return forgotPasswordApi(tenant_name_eng_tmp, data);
  }
);

export const resetPassword = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/resetPassword`,
  (data: ResetPasswordRequestBody) => {
    return resetPasswordApi(tenant_name_eng_tmp, data);
  }
);

export const verifyToken = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/verifyToken`,
  (token: string) => {
    return verifyTokenApi(tenant_name_eng_tmp, token);
  }
);

export const confirmResetPassword = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/confirmResetPassword`,
  (token: string) => {
    return confirmResetPasswordApi(tenant_name_eng_tmp, token);
  }
);

export const setupPassword = createApiAsyncThunk(
  `${AUTH_FEATURE_KEY}/setupPassword`,
  (data: SetupPasswordRequestBody) => {
    return setupPasswordApi(tenant_name_eng_tmp, data);
  }
);

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /**
     * LOGIN
     */
    builder.addCase(login.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      login.fulfilled,
      (state, action: PayloadAction<LoginResponse>) => {
        state.loading = false;
        state.isAuthenticated = true;
        saveTokens({ ...action.payload });
      }
    );
    builder.addCase(login.rejected, state => {
      state.loading = false;
      state.isAuthenticated = false;
      clearTokens();
    });

    builder.addCase(verifyToken.pending, state => {
      state.user_verify = null;
    });
    builder.addCase(verifyToken.fulfilled, (state, action) => {
      state.user_verify = action.payload;
    });
    builder.addCase(verifyToken.rejected, state => {
      state.user_verify = null;
    });

    builder.addCase(logoutServer.fulfilled, () => {
      clearTokens();
      clearCurrentTenantCookie();
    });
  },
});

export { AUTH_FEATURE_KEY, initialState as authInitialState };
export const authReducer = authSlice.reducer;

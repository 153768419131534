import cookies from "react-cookies";
import { NavigateFunction } from "react-router-dom";

import { getTenantEng } from "@app/features/tenants/tenants";
import { logoutServer, resetStore } from "@app/redux";
import store from "@app/redux/store";

import { AuthPaths } from "../auth";
import { AUTH_ACCESS_TOKEN } from "../constants/auth.constants";
import type { TokenCookieType, TokenType } from "../types/auth.types";

const isTokenCookie = (arg: unknown): arg is TokenCookieType => {
  return typeof arg === "object" && arg !== null && "access_token" in arg;
};

const loadAuthCookie = (): TokenCookieType | undefined => {
  const value = cookies.load(AUTH_ACCESS_TOKEN);
  if (!isTokenCookie(value)) return undefined;
  return value;
};

/**
 * cookie から token を取得する
 * * stateに current user が load 済みである場合、company_id が一致するかを確認する
 * @returns Token | undefined
 */
export const getTokens = (): TokenType | undefined => {
  const cookieInfo = loadAuthCookie();
  if (!cookieInfo) return undefined;

  const { company_id, ...tokens } = cookieInfo;

  const user = store.getState().users.currentUser;
  if (user) {
    if (user.company.id !== company_id) {
      return undefined;
    }
  }
  return tokens;
};

export const saveTokens = (token: TokenCookieType) => {
  const MAX_AGE = 60 * 60 * 24 * 365 * 2; // remember toekn max

  const path = getTenantEng();
  cookies.save(AUTH_ACCESS_TOKEN, token, { path: `/${path}`, maxAge: MAX_AGE });
};

export const clearTokens = () => {
  const path = getTenantEng();
  cookies.remove(AUTH_ACCESS_TOKEN, { path: `/${path}` });
};

/**
 * localログアウト処理
 *
 * 現状axios の error interceptorにおいてnavigateが解決できないため、
 * 呼び元からnavigateを受け取った場合のみ、route変更を行う
 *
 * @param navigate
 */
export const logoutLocal = (navigate?: NavigateFunction) => {
  store.dispatch(resetStore());
  clearTokens();
  if (navigate) navigate(AuthPaths.LOGIN(), { replace: true });
};

/**
 * logout for loader
 *
 * このタイミングでは、login画面に引き渡すstateの情報を作るのがトリッキーなため、
 * login画面への遷移は RequireAuth で行う
 */
export const logoutInLoader = async () => {
  const token = getTokens();
  if (token?.access_token) {
    await store.dispatch(logoutServer());
  }
  logoutLocal();
  return null;
};

import {
  ANSWER_STATUS,
  ANSWER_STATUS_TEXT,
  getAnswerStatusText,
} from "@app/features/reports/reports";

import {
  FetchQuestionCommon,
  FetchQuestionGroup,
  FetchQuestionTenant,
  INPUT_TYPE,
  InputTypeValue,
  QuestionAnswerDetail,
  QuestionAnswerDetailResponse,
  QuestionCommon,
  QuestionGroup,
  QuestionTenant,
  QuestionTenantOption,
  Questions,
} from "../question_answers";

/**
 * テナント共通質問を変換する
 * @param datas FetchQuestionCommon[]
 * @returns QuestionCommon[]
 */
export const convertQuestionCommon = (datas: FetchQuestionCommon[]) => {
  const result: QuestionCommon[] = datas.map(data => {
    return {
      questionCommonId: data.id,
      questionGroupId: data.question_group_id,
      fiscalYear: data.fiscal_year,
      inputType: data.input_type,
      title: data.title,
      sort: data.sort,
      answerText: data.answer_common?.answer_text ?? null,
    };
  });
  return result;
};

/**
 * テナント固有質問を変換する
 * @param datas FetchQuestionTenant[]
 * @returns QuestionTenant[]
 */
export const convertQuestionTenant = (datas: FetchQuestionTenant[]) => {
  const result: QuestionTenant[] = datas.map(data => {
    const res: QuestionTenant = {
      questionTenantId: data.question_tenant_id,
      questionGroupId: data.question_group_id,
      fiscalYear: data.fiscal_year,
      inputType: data.input_type,
      title: data.title,
      unit: data.unit,
      answerText: data.answer_tenant?.answer_text ?? null,
      selectedOptionId: data.answer_tenant?.option_id ?? null,
      optionAnswerText: data.answer_tenant?.option_answer_text ?? null,
      options: null,
      sort: data.sort,
    };
    if (data.input_type !== INPUT_TYPE.RADIO_BUTTON) return res;
    if (data.options === null) return res;
    // ラジオボタンの場合
    const options: QuestionTenantOption[] = data.options.map(option => {
      return {
        questionTenantId: option.question_tenant_id,
        questionTenantOptionId: option.question_tenant_option_id,
        inputType: option.input_type as InputTypeValue,
        optionName: option.option_name,
        inputName: option.input_name,
        unit: option.unit,
        optionAnswerText: null,
      };
    });
    res.options = options;
    return res;
  });
  return result;
};

/**
 * 設問グループを変換する
 * @param fetchData FetchQuestionGroup[]
 * @returns QuestionGroup[]
 */
const convertQuestionGroup = (
  fetchData: FetchQuestionGroup[]
): QuestionGroup[] => {
  const results: QuestionGroup[] = [];
  fetchData.forEach(data => {
    const result: QuestionGroup = {
      id: data.id,
      title: data.title,
      type: data.type,
      step: data.step,
      sort: data.sort,
      questions: null,
    };
    const { type } = result;
    let questions: Questions | null = null;
    switch (type) {
      case "common":
        questions = data.question_commons
          ? convertQuestionCommon(data.question_commons)
          : null;
        break;
      case "tenant":
        questions = data.question_tenants
          ? convertQuestionTenant(data.question_tenants)
          : null;
        break;
      default:
        break;
    }
    result.questions = questions;
    results.push(result);
  });
  return results;
};

/**
 * クライアント用にデータを変換する
 * @param fetchData QuestionAnswerDetailResponse
 * @param step number
 * @returns QuestionAnswerDetail
 */
export const convertFetchData = (
  fetchData: QuestionAnswerDetailResponse
): QuestionAnswerDetail => {
  const { answer: data, step } = fetchData;
  const statusLabel = getAnswerStatusText(
    data.input_status,
    data.tenant_approve_status
  );
  const result: QuestionAnswerDetail = {
    answerId: data.id,
    company: data.company,
    fiscalYear: data.fiscal_year,
    startAt: data.start_at,
    endAt: data.end_at,
    step,
    inputStatus: data.input_status,
    tenantApproveStatus: data.tenant_approve_status,
    statusLabel,
    isApproved: statusLabel === ANSWER_STATUS_TEXT[ANSWER_STATUS.APPROVED],
    questionGroups: data.question_groups
      ? convertQuestionGroup(data.question_groups)
      : null,
  };
  return result;
};

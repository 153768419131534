import { LoaderFunctionArgs, redirect } from "react-router-dom";

import { getTokens } from "../../auth";

export const loginLoader = async ({ params }: LoaderFunctionArgs) => {
  const { tenant_name_eng } = params;
  if (!tenant_name_eng) {
    throw Error();
  }

  const token = getTokens();

  if (token?.access_token) {
    return redirect(`/${tenant_name_eng}/`);
  }

  return {};
};

import { Branch } from "@app/features/branches/branches";
import {
  InputStatusValue,
  TenantApproveStatusValue,
} from "@app/features/question_answers/question_answers";
import { isYear } from "@app/helpers/util.helper";

import {
  ANSWER_INPUT_STATUS,
  ANSWER_STATUS,
  ANSWER_STATUS_TEXT,
  ListYears,
  TENANT_APPROVE_STATUS,
} from "../reports";

/**
 * クエリストリングの検証
 * @param step string | null
 * @param fiscalYear string | null
 * @param branchId string | null | undefined
 * @param listYear ListYears
 * @param branches Branch[]
 * @returns boolean
 */
export const validateQueryParams = ({
  step,
  fiscalYear,
  branchId,
  listYear,
  branches,
}: {
  step: string | null;
  fiscalYear: string | null;
  branchId?: string | null;
  listYear: ListYears;
  branches: Branch[];
}) => {
  if (!fiscalYear) return false;
  if (!isYear(fiscalYear)) return false;
  // step=5が指定された場合、step指定なしと同様に処理して、入力が終わってる場合のみ完了画面を表示するためここでクリアする
  if (step !== null && ![1, 2, 3, 4].includes(Number(step))) return false;
  if (!listYear.years.some(item => String(item) === fiscalYear)) return false;
  if (branchId && step !== "2") return false;
  if (branchId && !branches.some(item => String(item.id) === branchId))
    return false;
  return true;
};

/**
 * 回答ステータスの取得
 * @param inputStatus
 * @param tenantApproveStatus
 * @returns string
 */
export const getAnswerStatusText = (
  inputStatus: InputStatusValue,
  tenantApproveStatus: TenantApproveStatusValue
): string => {
  // 報告済み
  if (
    inputStatus === ANSWER_INPUT_STATUS.FINISHED &&
    tenantApproveStatus === TENANT_APPROVE_STATUS.PROGRESS
  )
    return ANSWER_STATUS_TEXT[ANSWER_STATUS.REPORTED];
  // 承認済み
  if (
    inputStatus === ANSWER_INPUT_STATUS.FINISHED &&
    tenantApproveStatus === TENANT_APPROVE_STATUS.FINISHED
  )
    return ANSWER_STATUS_TEXT[ANSWER_STATUS.APPROVED];
  // 作業中
  return ANSWER_STATUS_TEXT[ANSWER_STATUS.IN_PROGRESS];
};

import type { ThemeConfig } from "antd";
import type { AliasToken } from "antd/lib/theme/internal";
// NOTE: Input はこのMapに含まれない
// import { ComponentTokenMap } from "antd/es/theme/interface";

export type colorsType = {
  main_color_code?: string;
  second_color_code?: string;
  third_color_code?: string;
};

const inputStyle: Partial<AliasToken> = {
  colorBorder: "#BDBDBD",
  borderRadius: 5,
  colorPrimaryHover: "none",
  controlOutline: "none",
  colorError: "#FF0000",
  colorErrorBorderHover: "none",
  colorErrorOutline: "none",
  fontSize: 14,
  fontSizeLG: 17,
  controlHeight: 36,
  controlHeightLG: 61,
  colorTextPlaceholder: "#BDBDBD",
  colorTextDisabled: "#333333",
} as const;

const themeConfig = (
  main_color_code: string | undefined,
  third_color_code: string | undefined
) => {
  const config: ThemeConfig = {
    token: {
      colorPrimary: `#${main_color_code}`,
      fontSize: 14,
      fontFamily: "Noto Sans JP, Helvetica, Arial, sans-serif",
      fontSizeHeading1: 26,
      fontSizeHeading2: 24,
      fontSizeHeading3: 20,
      lineHeight: 1.44785714286,
      fontSizeHeading4: 16,
      lineHeightHeading1: 1.44807692308,
      lineHeightHeading2: 1.44791666667,
      lineHeightHeading3: 1.448125,
      borderRadius: 5,
      borderRadiusLG: 10,
      boxShadow: "none",
      boxShadowSecondary: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      colorTextBase: "#333",
      colorText: "#333",
    },
    components: {
      Button: {
        borderRadius: 20,
        colorBorder: `#${main_color_code}`,
        colorBgTextHover: "#fff",
        colorText: `#${main_color_code}`,
        lineWidth: 2,
        colorBgTextActive: `#${main_color_code}`,
        paddingXS: 8,
        paddingContentHorizontal: 16,
        colorPrimary: `#${main_color_code}`,
        colorPrimaryBorder: `#${main_color_code}`,
        colorPrimaryHover: "none",
        fontSize: 14,
        fontSizeLG: 18,
        lineHeight: 1.44823529412,
        lineType: "solid",
        lineWidthBold: 1,
        colorError: "#ff0000",
        colorBgContainerDisabled: "#EFEFEF",
        controlHeightLG: 55,
        colorTextDisabled: "#D0D9D8",
        borderRadiusLG: 27.5,
      },
      Menu: {
        itemSelectedBg: `#${main_color_code}`,
        itemSelectedColor: "#FFFFFF",
        horizontalItemSelectedColor: "#FFFFFF",
        itemHoverBg: "none",
        fontSize: 18,
        itemColor: "#333333",
        itemMarginInline: 0,
      },
      Radio: {
        fontSizeLG: 23,
        colorBorder: "#999999",
        lineWidth: 2,
        fontSize: 18,
        lineWidthBold: 2,
        colorPrimary: `#${main_color_code}`,
        colorWhite: `#${main_color_code}`,
        buttonCheckedBg: `#${third_color_code}`,
        buttonCheckedBgDisabled: `#${third_color_code}`,
        colorBgContainerDisabled: "transparent", // for radio button
        buttonPaddingInline: 20,
      },
      Select: {
        fontSize: 12,
        fontSizeIcon: 14,
        colorPrimaryHover: "none",
        colorBorder: "#bdbdbd",
        colorText: "#444444",
        controlHeight: 36,
        controlItemBgActive: `#${third_color_code}`,
        fontWeightStrong: 500,
        colorTextPlaceholder: "#444444",
        controlHeightSM: 32,
        borderRadiusSM: 9,
      },
      Input: inputStyle,
      InputNumber: inputStyle,
      DatePicker: {
        ...inputStyle,
      },
      Steps: {
        colorFillContent: "#FFFFFF",
        colorTextLabel: "#BDBDBD",
        colorBgContainer: "#fff",
        fontSizeLG: 14,
        colorText: `#${main_color_code}`,
        colorTextDescription: "#BDBDBD",
        colorTextLightSolid: `#${main_color_code}`,
        marginSM: 0,
      },
      Checkbox: {
        controlInteractiveSize: 23,
        colorPrimaryHover: "none",
        colorBorder: "#999999",
        lineWidth: 2,
        colorText: "#333333",
        paddingXS: 8,
        borderRadiusSM: 5,
        fontSize: 18,
        lineHeight: 1,
        marginXS: 8,
        colorBgContainer: "#ffffff",
        colorPrimary: `#${main_color_code}`,
        colorPrimaryBorder: "#6dcfe3",
      },
      Dropdown: {
        controlItemBgHover: `#${third_color_code}`,
        boxShadowSecondary: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      },
      Typography: {
        colorTextHeading: "#333333",
        fontSizeHeading1: 33.75,
        fontWeightStrong: 700,
        lineHeight: 1,
        fontSizeHeading2: 24,
      },
      Modal: {
        borderRadiusLG: 30,
        paddingContentHorizontalLG: 0,
        colorTextHeading: "#333333",
        colorText: "#333333",
        fontWeightStrong: 400,
        fontSizeHeading5: 22,
        fontSize: 16,
        paddingMD: 26,
        marginSM: 22,
      },
      Form: {
        colorBorder: "#B9B9B9",
        colorError: "#FF5B5B",
        colorText: "#282828",
        fontSize: 14,
        colorTextDescription: "#999",
        colorTextHeading: "#333",
        margin: 16,
      },
      Card: {
        borderRadiusLG: 16,
        colorBorderSecondary: "0",
      },
      Table: {
        fontWeightStrong: 700,
        fontSize: 12,
        paddingXS: 4,
        padding: 23,
        paddingSM: 15,
        margin: 16,
        colorFillSecondary: "rgba(51, 51, 51, 0.06)",
        colorBorderSecondary: "#F5F5F5",
        borderRadius: 10,
        borderRadiusLG: 15,
        colorTextHeading: "#fff",
      },
      Collapse: {
        padding: 0,
        fontSizeIcon: 24,
        colorFillAlter: "transparent",
      },
      Tag: {
        borderRadiusSM: 0,
        fontSizeSM: 14,
        lineHeight: 1.15,
      },
      Tabs: {
        colorBgContainer: `#${main_color_code}`,
        colorFillAlter: "#fff",
        colorPrimaryHover: `#${main_color_code}`,
      },
      Divider: {
        colorSplit: "#BDBDBD",
      },
    },
  };
  return config;
};

export default themeConfig;

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import { setupStore } from ".";

const store = setupStore();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./root-reducer", () => {
    const newRootReducer = require("./root-reducer").default;
    store.replaceReducer(newRootReducer);
  });
}
export default store;

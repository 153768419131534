import { api } from "@app/api/api";
import { CustomAxiosResponse } from "@app/api/api.types";

import type {
  ConfirmResetPasswordResponse,
  ForgotPasswordRequestBody,
  LoginRequestBody,
  LoginResponse,
  ResetPasswordRequestBody,
  SetupPasswordRequestBody,
  VerifyTokenResponse,
} from "../auth";
import { AuthEndpoints } from "../constants/auth.endpoints";

export const loginApi = (
  tenant_name_eng: string,
  data: LoginRequestBody
): Promise<CustomAxiosResponse<LoginResponse>> => {
  return api.post<LoginResponse, CustomAxiosResponse>(
    AuthEndpoints.LOGIN(tenant_name_eng),
    data
  );
};

export const logoutApi = (
  tenant_name_eng: string
): Promise<CustomAxiosResponse<void>> => {
  return api.post<void, CustomAxiosResponse>(
    AuthEndpoints.LOGOUT(tenant_name_eng)
  );
};

export const forgotPasswordApi = (
  tenant_name_eng: string,
  data: ForgotPasswordRequestBody
): Promise<CustomAxiosResponse> => {
  return api.post(AuthEndpoints.FORGOT_PASSWORD(tenant_name_eng), data);
};

export const resetPasswordApi = (
  tenant_name_eng: string,
  data: ResetPasswordRequestBody
): Promise<CustomAxiosResponse> => {
  return api.patch(AuthEndpoints.RESET_PASSWORD(tenant_name_eng), data);
};

export const setupPasswordApi = (
  tenant_name_eng: string,
  data: SetupPasswordRequestBody
): Promise<CustomAxiosResponse> => {
  return api.patch(AuthEndpoints.SETUP_PASSWORD(tenant_name_eng), data);
};

export const verifyTokenApi = (
  tenant_name_eng: string,
  token: string
): Promise<CustomAxiosResponse<VerifyTokenResponse>> => {
  return api.get<VerifyTokenResponse, CustomAxiosResponse>(
    AuthEndpoints.VERIFY_TOKEN(tenant_name_eng, token)
  );
};

export const confirmResetPasswordApi = (
  tenant_name_eng: string,
  token: string
): Promise<CustomAxiosResponse<ConfirmResetPasswordResponse>> => {
  return api.get<ConfirmResetPasswordResponse, CustomAxiosResponse>(
    AuthEndpoints.CONFIRM_RESET_PASSWORD(tenant_name_eng, token)
  );
};

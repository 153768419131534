import { useMemo } from "react";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import cx from "classnames";

import { useAppSelector } from "@app/redux";

import styles from "./LoadingSpinner.module.scss";

const { Title } = Typography;

interface LoadingSpinnerProps {
  text?: string;
  color?: string;
  isFullscreen?: boolean;
  useMainColor?: boolean;
}

const LoadingSpinner = ({
  text,
  color = "white",
  useMainColor,
  isFullscreen,
}: LoadingSpinnerProps) => {
  const main_color_code = useAppSelector(
    state => state.tenants.tenant?.main_color_code
  );

  const antIcon = useMemo(() => {
    const spinColor =
      useMainColor && main_color_code ? `#${main_color_code}` : color;
    return (
      <Loading3QuartersOutlined
        style={{ fontSize: 24, color: spinColor }}
        spin
      />
    );
  }, [color, useMainColor, main_color_code]);
  return (
    <div
      className={cx(styles.container, {
        [styles.isFullscreen]: isFullscreen,
      })}
    >
      {!!text && <Title level={3}>{text}</Title>}

      <Spin indicator={antIcon} />
    </div>
  );
};

export default LoadingSpinner;

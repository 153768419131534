import { LoaderFunction } from "react-router-dom";

import { logoutInLoader } from "@app/features/auth/auth";
import { setLocalTitlePage } from "@app/helpers/util.helper";
import { getCurrentUser, getTenant } from "@app/redux";
import store from "@app/redux/store";

const authenticatedLoader: LoaderFunction = async ({ params }) => {
  const { tenant_name_eng } = params;
  if (!tenant_name_eng) {
    throw Error();
  }

  setLocalTitlePage();

  try {
    const currentUser = await store.dispatch(getCurrentUser()).unwrap();
    const hasTenant = currentUser?.tenants.some(
      tenant => tenant.name_eng === tenant_name_eng
    );
    if (!hasTenant) {
      return logoutInLoader();
    }

    const { tenant } = store.getState().tenants;
    if (!tenant) {
      store.dispatch(getTenant(tenant_name_eng));
    }
  } catch (error) {
    return logoutInLoader();
  }

  return null;
};

export default authenticatedLoader;

import qs from "query-string";
import { LoaderFunction } from "react-router-dom";

import { clearInvoices, getListEnergyTypeWithUsageStatus } from "@app/redux";
import store from "@app/redux/store";

import { AnnualInvoiceParams } from "../../invoice";

/**
 * page loader
 * @param params
 * @returns
 */
const annualInvoiceEditorLoader = async (
  fiscal_year: number,
  branch_id: string
) => {
  // エネルギー種別取得
  const { energy_type_masters } = await store
    .dispatch(
      getListEnergyTypeWithUsageStatus({
        fiscal_year,
        branch_id,
      })
    )
    .unwrap();

  const listEnergyType = energy_type_masters;
  if (listEnergyType.length === 0)
    throw Error("valid energy type list not found");

  await store.dispatch(clearInvoices());
};

// NOTE: 今の所 reports 内での遷移のみを想定しているため、defer loadingは使用していない
const annualInvoiceEditorPageLoader: LoaderFunction = async params => {
  const { tenant_name_eng, branch_id } = params.params;
  if (!tenant_name_eng) throw Error();
  if (!branch_id) throw Error();

  const url = new URL(params.request.url);
  const searchParams = qs.parse(url.search, { parseNumbers: true });
  const { "fiscal-year": fiscal_year } = searchParams;

  if (!fiscal_year || typeof fiscal_year !== "number") return null; // 親routerでvalidationされる

  await annualInvoiceEditorLoader(fiscal_year, branch_id);
  const returnData: AnnualInvoiceParams = {
    branch_id: Number(branch_id),
    fiscal_year: Number(fiscal_year),
  };
  return returnData;
};

export default annualInvoiceEditorPageLoader;

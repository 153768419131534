// TODO: Update to use name of project
export const AUTH_ACCESS_TOKEN = "auth-access-tokens";

export const GRANT_TYPE = {
  PASSWORD: "password",
  REFRESH_TOKEN: "refresh_token",
};

export const TOKEN_TYPE = {
  VALID: "valid",
  INVALID: "invalid",
  EXPIRED: "expired",
};

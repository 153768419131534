import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "@app/redux";
import { createApiAsyncThunk, errorHandler } from "@app/redux/api.thunk";

import {
  listAnsweredFiscalYearApi,
  listEnergyTypeWithUsageStatusApi,
  listFiscalYearApi,
} from "../api/reports.api";
import { REPORT_KEY } from "../constants/reports.keys";
import type { EnergyType, EnergyTypeListRequestParams } from "../invoice";
import type { ListYears } from "../reports";

interface ReportStateType {
  listYears: ListYears | null;
  listAnsweredYears?: ListYears;
  listEnergyTypes: EnergyType[] | null;
  screenState: {
    isFormModified: boolean;
    isLoading: boolean;
  };
}

const initialState: ReportStateType = {
  listYears: null,
  listEnergyTypes: null,
  screenState: {
    isFormModified: false,
    isLoading: false,
  },
};

// NOTE: fetch済の場合は再度fetchしない
export const listFiscalYear = createAsyncThunk<
  ListYears,
  void,
  { state: RootState }
>(
  `${REPORT_KEY}/fiscalYear`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await listFiscalYearApi();
      return response.responseBody.data;
    } catch (err) {
      return rejectWithValue(errorHandler(err));
    }
  },
  {
    condition: (_, { getState }) => {
      const { report } = getState();
      const data = report.listYears;
      return data === null;
    },
  }
);

export const listAnsweredFiscalYear = createAsyncThunk<
  ListYears,
  void,
  { state: RootState }
>(
  `${REPORT_KEY}/answeredFiscalYear`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await listAnsweredFiscalYearApi();
      return response.responseBody.data;
    } catch (err) {
      return rejectWithValue(errorHandler(err));
    }
  },
  {
    condition: (_, { getState }) => {
      const { report } = getState();
      const data = report.listAnsweredYears;
      return !data || data.years.length === 0;
    },
  }
);

export const getListEnergyTypeWithUsageStatus = createApiAsyncThunk(
  `${REPORT_KEY}/energyTypes`,
  (params: EnergyTypeListRequestParams) => {
    return listEnergyTypeWithUsageStatusApi(params);
  }
);

export const reportSlice = createSlice({
  name: REPORT_KEY,
  initialState,
  reducers: {
    resetListAnsweredYears: state => {
      state.listAnsweredYears = undefined;
    },
    setFormIsModified(state, action) {
      state.screenState.isFormModified = action.payload;
    },
    beginAnswerLoading: state => {
      state.screenState.isLoading = true;
    },
    endAnswerLoading: state => {
      state.screenState.isLoading = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(listFiscalYear.fulfilled, (state, action) => {
      state.listYears = action.payload;
    });

    builder.addCase(listAnsweredFiscalYear.fulfilled, (state, action) => {
      state.listAnsweredYears = action.payload;
    });

    builder.addCase(
      getListEnergyTypeWithUsageStatus.fulfilled,
      (state, action) => {
        state.listEnergyTypes = action.payload.energy_type_masters;
      }
    );
    builder.addCase(getListEnergyTypeWithUsageStatus.rejected, state => {
      state.listEnergyTypes = null;
    });
  },
});

export { REPORT_KEY, initialState as reportInitialState };
export const {
  resetListAnsweredYears,
  setFormIsModified,
  beginAnswerLoading,
  endAnswerLoading,
} = reportSlice.actions;
export const reportReducer = reportSlice.reducer;

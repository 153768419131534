import { combineReducers, createAction, createReducer } from "@reduxjs/toolkit";

import {
  authReducer,
  authInitialState,
  branchesInitialState,
  branchesReducer,
  emissionInitialState,
  emissionReducer,
  energyUsageInitialState,
  energyUsageReducer,
  roadmapInitialState,
  roadmapReducer,
  questionAnswerReducer,
  questionAnswerInitialState,
  reportReducer,
  reportInitialState,
  tenantInitialState,
  tenantReducer,
  userReducer,
  usersInitialState,
  TENANTS_FEATURE_KEY,
  AUTH_FEATURE_KEY,
  USERS_FEATURE_KEY,
  BRANCHES_KEY,
  REPORT_KEY,
  QUESTION_ANSWER_KEY,
  EMISSION_KEY,
  ENERGY_USAGES_KEY,
  ROADMAP_KEY,
} from "@app/features/slices";

// NOTE: 新しくstoreを作成した際、リセットに含めるべきかを検討すること
const sliceReducers = combineReducers({
  [TENANTS_FEATURE_KEY]: tenantReducer,
  [AUTH_FEATURE_KEY]: authReducer,
  [REPORT_KEY]: reportReducer,
  [USERS_FEATURE_KEY]: userReducer,
  [BRANCHES_KEY]: branchesReducer,
  [QUESTION_ANSWER_KEY]: questionAnswerReducer,
  [EMISSION_KEY]: emissionReducer,
  [ENERGY_USAGES_KEY]: energyUsageReducer,
  [ROADMAP_KEY]: roadmapReducer,
});

export type RootState = ReturnType<typeof sliceReducers>;

export const rootInitState: RootState = {
  [TENANTS_FEATURE_KEY]: tenantInitialState,
  [AUTH_FEATURE_KEY]: authInitialState,
  [USERS_FEATURE_KEY]: usersInitialState,
  [BRANCHES_KEY]: branchesInitialState,
  [REPORT_KEY]: reportInitialState,
  [QUESTION_ANSWER_KEY]: questionAnswerInitialState,
  [EMISSION_KEY]: emissionInitialState,
  [ENERGY_USAGES_KEY]: energyUsageInitialState,
  [ROADMAP_KEY]: roadmapInitialState,
};

/**
 * root reducer` にstateリセット用actionを追加
 *
 * NOTE: 新しくstoreを作成した際、リセットに含めるべきかを検討すること
 */
const RESET_STORE = "reset_store";
const RESET_WITHOUT_AUTH = "reset_store_without_token";
export const resetStore = createAction(RESET_STORE);
export const resetWithoutAuth = createAction(RESET_WITHOUT_AUTH);

type RootReducer = typeof sliceReducers;

const rootReducer: RootReducer = createReducer(rootInitState, builder => {
  builder
    .addCase(resetStore, state => {
      return {
        ...rootInitState,
        [TENANTS_FEATURE_KEY]: state[TENANTS_FEATURE_KEY],
      };
    })
    .addCase(resetWithoutAuth, state => {
      return {
        ...rootInitState,
        [TENANTS_FEATURE_KEY]: state[TENANTS_FEATURE_KEY],
        [AUTH_FEATURE_KEY]: state[AUTH_FEATURE_KEY],
      };
    })
    .addDefaultCase((state, action) => {
      return sliceReducers(state, action);
    });
});

export default rootReducer;

import { memo } from "react";

import cx from "classnames";

import { type RootState, useAppSelector } from "@app/redux";

import styles from "./MenuContact.module.scss";

const MenuContact = () => {
  const {
    tenants: { tenant },
  } = useAppSelector((state: RootState) => state);

  return (
    <div
      className={cx(styles.menu_contact)}
      style={{ backgroundColor: `#${tenant?.main_color_code}` }}
    >
      <span className="font-16 mb-2">お問い合わせ</span>
      <div className="flex-justify-center flex-start full-width">
        <div className="menu_contact_content_title flex-start flex-justify-start flex-direction-column">
          <div className="width-fit-content">
            <span className="icon-phone font-12 d-block mr-1" />
            <span className="font-12 mb-1 ">電話</span>
          </div>
          <div className="flex-align-center menu_contact_content_email">
            <span className="icon-email font-12 d-block mr-1" />
            <span className="font-12 ">E-mail</span>
          </div>
        </div>

        <div className="menu_contact_content_text flex-start flex-justify-start flex-direction-column">
          <div>
            <span className="font-12 text-left">{tenant?.phone_number}</span>
          </div>
          <div className="text-left menu_contact_content_email">
            <a
              href={`mailto:${tenant?.email}`}
              className="font-12 text-white text-underline-skip-ink"
              style={{ width: "65%" }}
            >
              {tenant?.email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(MenuContact);

import { api } from "@app/api/api";
import { CustomAxiosResponse } from "@app/api/api.types";

import { UsersEndpoints } from "../constants/users.endpoints";
import {
  CreateUserCompletedRequestBody,
  GetCurrentUserResponse,
  UpdateUserRequestBody,
  UploadUserResponce,
} from "../users";

export const getCurrentUserApi = (
  tenant_name_eng: string
): Promise<CustomAxiosResponse<GetCurrentUserResponse>> => {
  return api.get<GetCurrentUserResponse, CustomAxiosResponse>(
    UsersEndpoints.GET_CURRENT_USER(tenant_name_eng)
  );
};

// not used
export const createUserCompletedApi = ({
  tenant_name_eng,
  data,
}: {
  tenant_name_eng: string;
  data: CreateUserCompletedRequestBody;
}): Promise<CustomAxiosResponse> => {
  return api.post(UsersEndpoints.CREATE_USER_COMPLETED(tenant_name_eng), data);
};

export const updateUserApi = ({
  tenant_name_eng,
  data,
}: {
  tenant_name_eng: string;
  data: UpdateUserRequestBody;
}): Promise<CustomAxiosResponse<UploadUserResponce>> => {
  return api.patch<
    UploadUserResponce,
    CustomAxiosResponse,
    UpdateUserRequestBody
  >(UsersEndpoints.UPDATE_USER(tenant_name_eng), data);
};

import { lazy, Suspense } from "react";

import Title from "antd/es/typography/Title";
import { RouteObject } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";

import branchInvoiceEditorPageLoader from "./loader/invoices.loader";
import annualReportLoader from "./loader/report.loader";
import annualInvoiceUsagesLoader from "./loader/usages.loader";
import { PATH_PARAM_BRANCH_ID } from "../reports";

const ScreenProvider = lazy(
  () => import("../screens/AnnualReportScreen/ScreenProvider")
);

const AnnualReportScreen = lazy(
  () => import("../screens/AnnualReportScreen/AnnualReportScreen")
);
const AnnualInvoiceUsageScreen = lazy(
  () => import("../screens/AnnualInvoiceUsageScreen/AnnualInvoiceUsageScreen")
);
const BranchInvoiceScreen = lazy(
  () => import("../screens/BranchInvoiceScreen/BranchInvoiceScreen")
);

const REPORTS_ROUTES: RouteObject[] = [
  {
    path: "reports",
    loader: annualReportLoader,
    element: (
      <Suspense
        fallback={<LoadingSpinner isFullscreen text="Loading please wait..." />}
      >
        <ScreenProvider />
      </Suspense>
    ),
    children: [
      // 年度データ入力
      {
        index: true,
        element: <AnnualReportScreen />,
      },
      {
        path: `:${PATH_PARAM_BRANCH_ID}`,
        children: [
          // 拠点毎の請求書編集
          {
            path: "edit",
            loader: branchInvoiceEditorPageLoader,
            element: <BranchInvoiceScreen />,
          },
          // 拠点毎の請求書プレビュー
          {
            index: true,
            loader: annualInvoiceUsagesLoader,
            element: <AnnualInvoiceUsageScreen />,
          },
        ],
      },
    ],
    errorElement: (
      <Title level={4} className="font-weight-medium text-center mt-8">
        現在、このページのデータがありません
      </Title>
    ),
  },
];
export default REPORTS_ROUTES;

// eslint-disable-next-line filenames/match-regex
import type { ColumnsType } from "antd/es/table";
import type { BarSeriesOption, EChartsOption, LineSeriesOption } from "echarts";

import RenderTableDate from "@app/components/molecules/RenderTableDate/RenderTableDate";
import RenderTableGrowthRate from "@app/components/molecules/RenderTableGrowthRate/RenderTableGrowthRate";
import RenderTableValue from "@app/components/molecules/RenderTableValue/RenderTableValue";
import { formatIntOrFloat, formatNumber } from "@app/helpers/util.helper";

import { ChartType, DataTypesEnergyUsage } from "../energy_usages";

export const INIT_DATA = [...Array(12)].map(() => 0);

export const ENERGY_USAGE_TAB_KEY = {
  indications: "indications",
  others: "others",
} as const;

export const NUMBER_OF_INDICATED_TABS = 10;

export const COLUMN_SUMMARY_ENERGY_USAGE = (
  year: number,
  unit: string,
  closingMonth: number
): ColumnsType<DataTypesEnergyUsage> => {
  return [
    {
      title: "年月",
      dataIndex: "month",
      width: "23%",
      align: "center",
      render: month => (
        <RenderTableDate
          month={month}
          year={year}
          closingMonth={closingMonth}
        />
      ),
    },
    {
      title: `使用量`,
      dataIndex: "usageAmount",
      render: value => <RenderTableValue value={value} unit={unit} />,
      align: "center",
    },
    {
      title: "前年同月比",
      dataIndex: "usageGrowthRate",
      render: value => <RenderTableGrowthRate value={value} />,
      align: "center",
    },
    {
      title: "コスト",
      dataIndex: "costAmount",
      render: value => <RenderTableValue value={value} unit="円" />,
      align: "center",
    },
    {
      title: "前年同月比",
      dataIndex: "costGrowthRate",
      render: value => <RenderTableGrowthRate value={value} />,
      align: "center",
    },
  ];
};

export const OPTION_CHART = (
  dataChart: ChartType,
  months: number[],
  unit: string
): EChartsOption => {
  return {
    legend: {
      data: ["使用量", "使用量（前年）", "コスト", "コスト（前年）"],
      textStyle: {
        fontWeight: 700,
      },
      bottom: 10,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        label: {
          formatter: "{value}月",
        },
      },
    },
    grid: {
      left: 70,
      right: 70,
      bottom: 120,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: months,
      offset: 50,
      axisPointer: {
        type: "shadow",
      },
      axisLabel: {
        fontSize: 16,
        color: "#666666",
        formatter: (value: string) => `${value}月`,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: [
      {
        type: "value",
        name: `［ ${unit} ］`,
        offset: 10,
        nameTextStyle: {
          align: "right",
          color: "#999999",
          fontWeight: 400,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#ccc",
            type: [5, 2],
          },
        },
        axisLabel: {
          color: "#999999",
        },
      },
      {
        type: "value",
        name: "［ 円 ］",
        offset: 10,
        nameTextStyle: {
          align: "left",
          color: "#999999",
          fontWeight: 400,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: "#999999",
        },
      },
    ],
    series: [
      {
        name: "使用量",
        type: "line" as const,
        lineStyle: {
          width: 5,
        },
        yAxisIndex: 0,
        zlevel: 11,
        connectNulls: true,
        data: dataChart.usage,
        color: "#FF6C00",
        symbolSize: 9,
        tooltip: {
          valueFormatter: data => {
            const v = typeof data === "number" ? formatIntOrFloat(data) : data;
            return `${v ?? "-"} ${unit}`;
          },
        },
      } as LineSeriesOption,
      {
        name: "使用量（前年）",
        type: "line" as const,
        lineStyle: {
          width: 5,
          type: "dotted",
        },
        yAxisIndex: 0,
        zlevel: 12,
        connectNulls: true,
        data: dataChart.prevUsage,
        color: "#FFBA89",
        symbolSize: 9,
        tooltip: {
          show: false,
        },
      } as LineSeriesOption,
      {
        name: "コスト（前年）",
        type: "bar" as const,
        data: dataChart.prevCost,
        color: "#FFD88E",
        barWidth: 18,
        barGap: "0%",
        yAxisIndex: 1,
        zlevel: 1,
        tooltip: {
          show: false,
        },
      } as BarSeriesOption,
      {
        name: "コスト",
        type: "bar" as const,
        data: dataChart.cost,
        color: "#FFA700",
        barWidth: 18,
        barGap: "0%",
        yAxisIndex: 1,
        zlevel: 2,
        tooltip: {
          valueFormatter: data => {
            const v = typeof data === "number" ? formatNumber(data) : data;
            return `${v ?? "-"} 円`;
          },
        },
      } as BarSeriesOption,
    ],
  };
};

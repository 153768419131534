import cookie from "react-cookies";

import {
  MAIN_COLOR,
  SECOND_COLOR,
  THIRD_COLOR,
} from "@app/styles/common_colors";

import { TENANT_COOKIE } from "../tenants";
import { GetTenantResponse } from "../types/tenants.types";

export const getTenantEng = () => {
  const url = new URL(window.location.href);
  const paths = url.pathname.split("/");
  if (paths.length >= 2) {
    return paths[1];
  }
  return "";
};

export const getCurrentTenant = () => {
  const cookieTenant: GetTenantResponse | undefined =
    cookie.load(TENANT_COOKIE);

  if (cookieTenant && !cookieTenant?.main_color_code) {
    cookieTenant.main_color_code = MAIN_COLOR;
  }

  if (cookieTenant && !cookieTenant?.second_color_code) {
    cookieTenant.second_color_code = SECOND_COLOR;
  }

  if (cookieTenant && !cookieTenant?.third_color_code) {
    cookieTenant.third_color_code = THIRD_COLOR;
  }

  return cookieTenant;
};

export const saveCurrentTenantCookie = (tenant: GetTenantResponse) => {
  const path = getTenantEng();
  cookie.save(TENANT_COOKIE, tenant, { path: `/${path}` });
};

export const clearCurrentTenantCookie = () => {
  const path = getTenantEng();
  return cookie.remove(TENANT_COOKIE, { path: `/${path}` });
};

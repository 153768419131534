import { getTenantEng } from "@app/features/tenants/tenants";
import { createApiAsyncThunk } from "@app/redux/api.thunk";

import { putAnswerApi, updateStatusAnswerApi } from "../api/answers.api";
import { ANSWERS_KEY } from "../constants/answers.keys";
import type { Answer, UpdateStatusAnswerType } from "../types/answers.types";

const tenant_name_eng = getTenantEng();

export const putAnswer = createApiAsyncThunk(
  `${ANSWERS_KEY}/answer`,
  (PutAnswerRequestBody: Answer) => {
    return putAnswerApi(tenant_name_eng, PutAnswerRequestBody);
  }
);

export const updateStatusAnswer = createApiAsyncThunk(
  `${ANSWERS_KEY}/answer/update`,
  (UpdateStatusAnswerRequestBody: UpdateStatusAnswerType) => {
    return updateStatusAnswerApi(
      tenant_name_eng,
      UpdateStatusAnswerRequestBody
    );
  }
);

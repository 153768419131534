import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { openNotification } from "@app/components/molecules/Notification/notification";
import { clearTokens, getTokens, logoutLocal } from "@app/features/auth/auth";
// eslint-disable-next-line no-restricted-imports
import { GRANT_TYPE } from "@app/features/auth/constants/auth.constants";
import { login } from "@app/redux";
import store from "@app/redux/store";

/**
 * Adds authorization headers to API calls
 * @param {InternalAxiosRequestConfig} request
 */
const authInterceptor = async (request: InternalAxiosRequestConfig) => {
  request.auth = {
    username: process.env.REACT_APP_AUTH_USER ?? "",
    password: process.env.REACT_APP_AUTH_PASSWORD ?? "",
  };
  const token = getTokens();
  if (token?.access_token) {
    request.headers.AuthorizationAPI = `Bearer ${token?.access_token}`;
  }
  return request;
};

export const isAxiosError = (error?: any): error is AxiosError => {
  return !!error?.isAxiosError;
};

const isLogoutRequest = (error: unknown) => {
  const logoutApiReg = /\/api\/v1\/(.+)\/logout/;
  if (isAxiosError(error)) {
    return logoutApiReg.test(error.response?.request?.responseURL);
  }
  return false;
};

/**
 * handle error
 */
const errorInterceptor = async (error: unknown) => {
  if (!isAxiosError(error)) {
    return Promise.reject(error);
  }
  const axiosError = error;
  const statusCode = axiosError.response?.status;
  const loginApiReg = /\/api\/v1\/(.+)\/login/;
  switch (statusCode) {
    case 500:
      openNotification({
        type: "error",
        message: "500 Internal Server Error",
        description:
          "正常に処理できませんでした。時間をおいて再度お試しください。それでも解消されない場合、サポートまでお問い合わせください。",
        reloadBtn: true,
      });
      break;
    case 408: {
      openNotification({
        type: "error",
        message: "408 Request Timeout",
        description:
          "タイムアウトが発生しました。時間をおいて再度お試しください。",
        reloadBtn: true,
      });
      break;
    }
    case 404: {
      // 主に存在しないtenantにアクセスされた場合
      clearTokens();
      break;
    }
    case 403:
      if (isLogoutRequest(axiosError)) break;
      // NOTE: navigateを解決するまで、ログアウト画面に遷移させられない
      // (hrefを使うとnoticicationが消えてしまうため)
      logoutLocal();
      openNotification({
        type: "error",
        message: "403 Forbidden",
        description: "セッションが切れました。再度ログインしてください。",
      });
      break;

    case 401:
      if (isLogoutRequest(axiosError)) break;
      // NOTE: navigateを解決するまで、ログアウト画面に遷移させられない
      // (hrefを使うとnoticicationが消えてしまうため)
      logoutLocal();
      await openNotification({
        type: "error",
        message: "401 Unauthorized",
        description: "セッションが切れました。再度ログインしてください。",
      });
      break;
    case 400:
      // NOTE: ログインページだけ通知をださない。
      if (loginApiReg.test(axiosError?.response?.request.responseURL)) break;
      openNotification({
        type: "error",
        message: "400 Bad Request",
        description:
          "正常に処理できませんでした。時間をおいて再度お試しください。それでも解消されない場合、サポートまでお問い合わせください。",
        reloadBtn: true,
      });
      break;
    default:
    // code block
  }
  return Promise.reject(axiosError);
};

// Function that will be called to refresh authorization
const refreshAuthLogic = async (error: unknown) => {
  const token = getTokens();
  if (!token?.refresh_token) {
    return Promise.reject();
  }
  if (isLogoutRequest(error)) {
    // logout apiは token refresh しない
    return Promise.reject();
  }
  const { refresh_token } = token;
  await store.dispatch(
    login({
      refresh_token,
      grant_type: GRANT_TYPE.REFRESH_TOKEN,
    })
  );
  return Promise.resolve();
};
/**
 * Axios success response interceptor
 * @param {AxiosResponse} response
 */
const responseInterceptor = (
  response: AxiosResponse | AxiosResponse["data"]
) => {
  if (typeof response === "object" && "data" in response) {
    return response.data;
  }
  // NOTE: refreshAuthLogicでリトライされた場合、responseInterceptorを2回通る
  // 1度目のresponse(retry response)で response.dataが返却されるため、
  // 2回目(original request response)は retry の (typeof response.data) になる
  // 通常 CustomAxiosResponse or string(csv api)
  return response;
};

/** Setup an API instance */
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

createAuthRefreshInterceptor(api, refreshAuthLogic);
api.interceptors.request.use(authInterceptor);
api.interceptors.response.use(responseInterceptor, errorInterceptor);

import { useCallback, useMemo } from "react";

import {
  useAsyncValue,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  beginAnswerLoading,
  endAnswerLoading,
  useAppDispatch,
  useAppSelector,
} from "@app/redux";

import { PATH_PARAM_BRANCH_ID, ReportPaths } from "../reports";
import { AnnualReportLoader } from "../routes/loader/report.loader";

export const useAsyncReportLoader = () => useAsyncValue() as AnnualReportLoader;

// loading switch dispatches
export const useBeginAnswerLoading = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(beginAnswerLoading()), [dispatch]);
};

export const useEndAnswerLoading = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(endAnswerLoading()), [dispatch]);
};

export const useAnswerLoading = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      beginLoading: () => dispatch(beginAnswerLoading()),
      endLoading: () => dispatch(endAnswerLoading()),
    }),
    [dispatch]
  );
};

type Navigate = (params: { nextStep?: number; nextYear?: number }) => void;

/**
 * レポート画面のstep間遷移のためのナビゲーション関数を返す
 * @returns Navigate
 */
export const useAnswerNavigate = () => {
  const questionAnswerDetail = useAppSelector(
    state => state.questionAnswer.questionAnswerDetail
  );
  const { fiscalYear } = questionAnswerDetail ?? {
    fiscalYear: 0,
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const beginLoading = useBeginAnswerLoading();
  const urlParam = useParams();
  const inBranchRoute = !!urlParam[PATH_PARAM_BRANCH_ID];

  const navigateReport = useCallback<Navigate>(
    ({ nextStep, nextYear }) => {
      if (!nextStep) {
        searchParams.set("fiscal-year", String(nextYear));
        searchParams.delete("step");
      } else {
        if (!fiscalYear) return;
        searchParams.set("fiscal-year", String(fiscalYear));
        searchParams.set("step", String(nextStep));
      }

      beginLoading();
      if (inBranchRoute) {
        navigate({
          pathname: ReportPaths.REPORT(),
          search: searchParams.toString(),
        });
      } else {
        navigate(
          {
            search: searchParams.toString(),
          },
          { relative: "path" }
        );
      }
    },
    [fiscalYear, beginLoading, navigate, searchParams, inBranchRoute]
  );

  return navigateReport;
};

/**
 * 拠点毎の請求書データ入力画面間の遷移のためのナビゲーション関数を返す
 * @returns (pathname: string) => void
 */
export const useInvoiceNavigate = () => {
  const beginLoading = useBeginAnswerLoading();
  const location = useLocation();
  const navigate = useNavigate();
  return useCallback(
    (pathname: string) => {
      beginLoading();
      navigate({
        pathname,
        search: location.search,
      });
    },
    [beginLoading, navigate, location.search]
  );
};

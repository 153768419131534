import { memo } from "react";

import { Helmet } from "react-helmet";

type FaviconProps = {
  href?: string;
};

const Favicon = ({ href }: FaviconProps) => {
  if (!href) return <></>;

  return (
    <Helmet>
      <link rel="icon" href={href} />
    </Helmet>
  );
};

export default memo(Favicon);

import { api } from "@app/api/api";
import { CustomAxiosResponse } from "@app/api/api.types";

import {
  BranchesEndpoints,
  GetBranchesResponse,
  GetInvoicesParams,
  InvoiceListResponse,
  PutInvoicesData,
} from "../branches";
import {
  FetchAnswerInvoiceResponse,
  InvoiceAnswerListRequest,
} from "../types/branches.invoice.answer.fetch.types";

export const getBranchesApi = (
  tenant_name_eng: string
): Promise<CustomAxiosResponse<GetBranchesResponse>> => {
  return api.get<GetBranchesResponse, CustomAxiosResponse>(
    BranchesEndpoints.GET_BRANCHES(tenant_name_eng)
  );
};

export const invoiceAnswerListApi = (
  tenant_name_eng: string,
  params: InvoiceAnswerListRequest
): Promise<CustomAxiosResponse<FetchAnswerInvoiceResponse>> => {
  return api.get<FetchAnswerInvoiceResponse, CustomAxiosResponse>(
    BranchesEndpoints.INVOICE_ANSWER_LIST(tenant_name_eng),
    {
      params,
    }
  );
};

export const getInvoiceListApi = (
  tenant_name_eng: string,
  params: GetInvoicesParams
) => {
  return api.get<InvoiceListResponse, CustomAxiosResponse<InvoiceListResponse>>(
    BranchesEndpoints.GET_INVOICES(tenant_name_eng),
    { params }
  );
};

export const putInvoicesApi = (
  tenant_name_eng: string,
  data: PutInvoicesData
): Promise<CustomAxiosResponse> => {
  return api.put(BranchesEndpoints.PUT_INVOICES(tenant_name_eng), data);
};

import { unwrapResult } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { redirect } from "react-router-dom";

import { AuthPaths } from "@app/features/auth/auth";
import { getTenant } from "@app/redux";
import store from "@app/redux/store";
import { RouteGroup, RouteItem } from "@app/types/route.types";

export const flatten = (items: Array<RouteItem | RouteGroup>) => {
  const flat: RouteItem[] = [];

  items.forEach(item => {
    if (Array.isArray(item.nestedRoutes)) {
      flat.push(...flatten(item.nestedRoutes));
    } else {
      flat.push(item as RouteItem);
    }
  });

  return flat;
};

/**
 * テナント情報を取得する
 * @deprecated
 */
export const getTenantUser = async (tenant_name_eng: string) => {
  const tenant = await store
    .dispatch(getTenant(tenant_name_eng))
    .then(unwrapResult);
  if (isEmpty(tenant)) {
    // NOTE: 今componentで呼び出しているが、これでは遷移しない
    // 利用方法と役割の整理が必要
    redirect(AuthPaths.LOGIN());
  }
};

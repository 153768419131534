import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getTenantEng } from "@app/features/tenants/tenants";
import { createApiAsyncThunk } from "@app/redux/api.thunk";

import { questionAnswerDetailApi } from "../api/question_answers.api";
import { QUESTION_ANSWER_KEY } from "../constants/question_answers.keys";
import {
  convertFetchData,
  getQuestionAnswerDetailParams,
  QuestionAnswerDetail,
  QuestionAnswerDetailResponse,
} from "../question_answers";

export interface QuestionAnswerStateType {
  questionAnswerDetail: QuestionAnswerDetail | null;
}

const initialState: QuestionAnswerStateType = {
  questionAnswerDetail: null,
};

const tenant_name_eng = getTenantEng();

export const getQuestionAnswerDetail = createApiAsyncThunk(
  `${QUESTION_ANSWER_KEY}/question-answer`,
  (param: getQuestionAnswerDetailParams) => {
    return questionAnswerDetailApi(
      tenant_name_eng,
      param.fiscal_year,
      param.step
    );
  }
);

export const questionAnswerSlice = createSlice({
  name: QUESTION_ANSWER_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getQuestionAnswerDetail.fulfilled,
      (state, action: PayloadAction<QuestionAnswerDetailResponse>) => {
        state.questionAnswerDetail = convertFetchData(action.payload);
      }
    );
    builder.addCase(getQuestionAnswerDetail.rejected, state => {
      state.questionAnswerDetail = null;
    });
  },
});

export { QUESTION_ANSWER_KEY, initialState as questionAnswerInitialState };
export const questionAnswerReducer = questionAnswerSlice.reducer;

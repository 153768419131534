import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getTenantEng } from "@app/features/tenants/tenants";
import { createApiAsyncThunk, errorHandler } from "@app/redux/api.thunk";
import { RootState } from "@app/redux/root-reducer";

import {
  getBranchesApi,
  getInvoiceListApi,
  invoiceAnswerListApi,
  putInvoicesApi,
} from "../api/branches.api";
import type {
  BranchInitialState,
  GetBranchesResponse,
  GetInvoicesParams,
  PutInvoicesData,
} from "../branches";
import { BRANCHES_KEY } from "../constants/branches.keys";
import { InvoiceAnswerListRequest } from "../types/branches.invoice.answer.fetch.types";

const initialState: BranchInitialState = {
  branches: [],
  isFulfilled: false,
  invoices: {
    arg: null,
    data: [],
  },
  monthlyInvoices: [],
};

const tenant_name_eng = getTenantEng();

// NOTE: fetch済の場合は再度fetchしない
// TODO: load とrefreshのタイミングについて、企業切り替えを踏まえて要検討
export const getBranches = createAsyncThunk<
  GetBranchesResponse,
  void,
  { state: RootState }
>(
  `${BRANCHES_KEY}/branches`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getBranchesApi(tenant_name_eng);
      return response.responseBody.data;
    } catch (err) {
      return rejectWithValue(errorHandler(err));
    }
  },
  {
    condition: (_, { getState }) => {
      const { branches: state } = getState();
      if (state.isFulfilled) return false;
      return true;
    },
  }
);

export const getInvoiceAnswerList = createApiAsyncThunk(
  `${BRANCHES_KEY}/invoiceAnswerList`,
  (param: InvoiceAnswerListRequest) => {
    return invoiceAnswerListApi(tenant_name_eng, param);
  }
);

export const getInvoiceList = createApiAsyncThunk(
  `${BRANCHES_KEY}/invoices/get`,
  (params: GetInvoicesParams) => {
    return getInvoiceListApi(tenant_name_eng, params);
  }
);

export const putInvoices = createApiAsyncThunk(
  `${BRANCHES_KEY}/invoices/put`,
  (data: PutInvoicesData) => {
    return putInvoicesApi(tenant_name_eng, data);
  }
);

export const branchesSlice = createSlice({
  name: BRANCHES_KEY,
  initialState,
  reducers: {
    clearInvoices: state => {
      state.invoices = {
        arg: null,
        data: [],
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(getBranches.fulfilled, (state, action) => {
      state.branches = action.payload.branches;
      state.isFulfilled = true;
    });
    builder.addCase(getInvoiceAnswerList.fulfilled, (state, action) => {
      const data = action.payload;
      state.monthlyInvoices = data.invoices;
    });
    builder.addCase(getInvoiceList.fulfilled, (state, action) => {
      state.invoices = {
        arg: action.meta.arg,
        data: action.payload.invoices,
      };
    });
    builder.addCase(getInvoiceList.rejected, state => {
      state.invoices = {
        arg: null,
        data: [],
      };
    });
  },
});

export { BRANCHES_KEY, initialState as branchesInitialState };
export const { clearInvoices } = branchesSlice.actions;
export const branchesReducer = branchesSlice.reducer;

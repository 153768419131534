import React from "react";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from "react-router-dom";

import store from "@app/redux/store";

import LoadingSpinner from "./components/atoms/LoadingSpinner/LoadingSpinner";
import { AuthPaths, getTokens } from "./features/auth/auth";
import { ReportPaths } from "./features/reports/reports";
import reportWebVitals from "./reportWebVitals";
import AuthenticatedLayout from "./routes/components/AuthenticatedLayout/AuthenticatedLayout";
import BlankLayout from "./routes/components/BlankLayout/BlankLayout";
import NotFound from "./routes/components/NotFound/NotFound";
import authenticatedLoader from "./routes/loaders/authenticated.loader";
import { rootLoader } from "./routes/loaders/root.loader";
import { PRIVATE_LIST, PUBLIC_LIST } from "./routes/routes.config";
import "./index.scss";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const token = getTokens();
  const location = useLocation();
  if (!token?.access_token) {
    return (
      <Navigate to={AuthPaths.LOGIN()} state={{ from: location }} replace />
    );
  }
  return children;
};

const router = createBrowserRouter([
  {
    path: "/:tenant_name_eng",
    errorElement: <NotFound />,
    children: [
      {
        element: <BlankLayout />,
        loader: rootLoader,
        children: PUBLIC_LIST,
      },
      {
        element: (
          <RequireAuth>
            <AuthenticatedLayout />
          </RequireAuth>
        ),
        loader: authenticatedLoader,
        children: PRIVATE_LIST,
        errorElement: <NotFound />,
      },
      {
        index: true,
        element: (
          <>
            <LoadingSpinner isFullscreen />
            <Navigate to={ReportPaths.REPORT()} replace />
          </>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const render = () => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider
          router={router}
          fallbackElement={<LoadingSpinner isFullscreen />}
        />
      </Provider>
    </React.StrictMode>
  );
};

render();

// if (process.env.NODE_ENV === "development" && module.hot) {
//   module.hot.accept("./App", render);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { api } from "@app/api/api";
import type { CustomAxiosResponse } from "@app/api/api.types";

import {
  type QuestionAnswerDetailResponse,
  QuestionAnswerEndpoints,
} from "../question_answers";

export const questionAnswerDetailApi = (
  tenant_name_eng: string,
  fiscal_year: number,
  step: number | null
) => {
  return api.get<
    QuestionAnswerDetailResponse,
    CustomAxiosResponse<QuestionAnswerDetailResponse>
  >(QuestionAnswerEndpoints.QUESTION_ANSWER_DETAIL(tenant_name_eng), {
    params: { fiscal_year, step },
  });
};

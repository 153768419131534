/* eslint-disable no-restricted-imports */
import AUTH_ROUTES from "@app/features/auth/routes/auth.routes";
import EMISSIONS_ROUTES from "@app/features/emissions/routes/emissions.routes";
import ENERGY_USAGES_ROUTES from "@app/features/energy_usages/routes/energy_usages.routes";
import REPORTS_ROUTES from "@app/features/reports/routes/reports.routes";
import ROADMAP_ROUTES from "@app/features/roadmap/routes/roadmap.routes";
import USERS_ROUTES from "@app/features/users/routes/users.routes";

export const ROOT_ROUTE = "/";

export const PUBLIC_LIST = [...AUTH_ROUTES];
export const PRIVATE_LIST = [
  ...USERS_ROUTES,
  ...REPORTS_ROUTES,
  ...ENERGY_USAGES_ROUTES,
  ...EMISSIONS_ROUTES,
  ...ROADMAP_ROUTES,
];

export const AuthEndpoints = {
  LOGIN: (tenant_name_eng: string) => `/api/v1/${tenant_name_eng}/login`,
  LOGOUT: (tenant_name_eng: string) => `api/v1/${tenant_name_eng}/logout`,
  FORGOT_PASSWORD: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/forgot-password`,
  RESET_PASSWORD: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/reset-password`,
  SETUP_PASSWORD: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/users/complete`,
  VERIFY_TOKEN: (tenant_name_eng: string, token: string) =>
    `api/v1/${tenant_name_eng}/users/${token}/verify`,
  CONFIRM_RESET_PASSWORD: (tenant_name_eng: string, token: string) =>
    `api/v1/${tenant_name_eng}/reset-password/${token}/verify`,
};

import { getTenantEng } from "@app/features/tenants/tenants";

const tenant_name_eng = getTenantEng();
export enum UsersRoutePaths {
  ACCOUNT_INFORMATION = "/:tenant_name_eng/account/information",
  ACCOUNT_COMPANIES = "/:tenant_name_eng/account/companies",
}

export const UsersPaths = {
  ACCOUNT_INFORMATION: () => `/${tenant_name_eng}/account/information`,
  ACCOUNT_COMPANIES: () => `/${tenant_name_eng}/account/companies`,
};

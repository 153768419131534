import { defer, LoaderFunction } from "react-router-dom";

import { getCo2EmissionsByRoadmap } from "@app/redux";
import store from "@app/redux/store";

import type { RoadmapLoaderReturn } from "../../roadmap";

/**
 * page loader
 * @param params
 * @returns
 */
const roadmapPageLoader: LoaderFunction = async params => {
  const { tenant_name_eng } = params.params;
  // テナント名が無い場合はエラー
  if (!tenant_name_eng) throw Error();

  // promise 作成
  const roadmapPromise = store
    .dispatch(getCo2EmissionsByRoadmap())
    .unwrap()
    .then(res => res);

  const loaderData: RoadmapLoaderReturn = {
    deferData: roadmapPromise,
  };
  return defer(loaderData);
};

export default roadmapPageLoader;

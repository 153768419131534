import { unwrapResult } from "@reduxjs/toolkit";
import { redirect, LoaderFunctionArgs } from "react-router-dom";

import { verifyToken } from "@app/redux";
import store from "@app/redux/store";

import { AuthPaths } from "../../auth";
import { TOKEN_TYPE } from "../../constants/auth.constants";

const verifyTokenLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const { tenant_name_eng } = params;
  if (!tenant_name_eng) {
    throw Error();
  }

  const url = new URL(request.url);
  const token = url.searchParams.get("token");

  if (!token) {
    return redirect(AuthPaths.LOGIN());
  }
  const result = await store.dispatch(verifyToken(token)).then(unwrapResult);
  if (result.token !== TOKEN_TYPE.VALID) {
    return redirect(AuthPaths.LOGIN());
  }
  return { token };
};

export default verifyTokenLoader;

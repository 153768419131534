import { api } from "@app/api/api";
import { CustomAxiosResponse } from "@app/api/api.types";

import { EmissionEndPoints } from "../emissions";
import type {
  Co2EmissionByBranchRequestParams,
  Co2EmissionByBranchResponseBody,
  Co2EmissionByMonthRequestParams,
  Co2EmissionByMonthResponseBody,
  Co2EmissionByScopeRequestParams,
  Co2EmissionByScopeResponseBody,
  Co2EmissionRequestParams,
  Co2EmissionResponseBody,
} from "../emissions";

export const co2EmissionApi = (
  tenant_name_eng: string,
  params: Co2EmissionRequestParams
): Promise<CustomAxiosResponse<Co2EmissionResponseBody>> => {
  return api.get<Co2EmissionResponseBody, CustomAxiosResponse>(
    EmissionEndPoints.CO2_EMISSION(tenant_name_eng),
    { params }
  );
};

export const co2EmissionByMonthApi = (
  tenant_name_eng: string,
  params: Co2EmissionByMonthRequestParams
): Promise<CustomAxiosResponse<Co2EmissionByMonthResponseBody>> => {
  return api.get<Co2EmissionByMonthResponseBody, CustomAxiosResponse>(
    EmissionEndPoints.CO2_EMISSION_BY_MONTH(tenant_name_eng),
    {
      params,
    }
  );
};

export const co2EmissionByBranchApi = (
  tenant_name_eng: string,
  params: Co2EmissionByBranchRequestParams
): Promise<CustomAxiosResponse<Co2EmissionByBranchResponseBody>> => {
  return api.get<Co2EmissionByBranchResponseBody, CustomAxiosResponse>(
    EmissionEndPoints.CO2_EMISSION_BY_BRANCH(tenant_name_eng),
    {
      params,
    }
  );
};

export const co2EmissionByScopeApi = (
  tenant_name_eng: string,
  params: Co2EmissionByScopeRequestParams
): Promise<CustomAxiosResponse<Co2EmissionByScopeResponseBody>> => {
  return api.get<Co2EmissionByScopeResponseBody, CustomAxiosResponse>(
    EmissionEndPoints.CO2_EMISSION_BY_SCOPE(tenant_name_eng),
    {
      params,
    }
  );
};

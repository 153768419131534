import qs from "query-string";
import { LoaderFunction, LoaderFunctionArgs, defer } from "react-router-dom";

import { ALL_BRANCH_SELECT_LABEL } from "@app/constants/constants";
import { createMonthsFromClosingMonth } from "@app/helpers/util.helper";
import {
  energyUsageByMonth,
  energyUsageType,
  getBranches,
  getCurrentUser,
  listAnsweredFiscalYear,
  resetEnergyUsageType,
  resetListAnsweredYears,
} from "@app/redux";
import store from "@app/redux/store";

import {
  EnergyUsageType,
  EnergyTypeTabs,
  EnergyTypeTabsProps,
  NUMBER_OF_INDICATED_TABS,
  EnergyUsageLoaderData,
  EnergyUsageLoaderReturn,
} from "../../energy_usages";

export const energyUsageLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<EnergyUsageLoaderData> => {
  const { tenant_name_eng } = params;
  if (!tenant_name_eng) throw Error();

  const url = new URL(request.url);
  if (!url.search) {
    // パラメータなしの場合は再取得するようにリセット
    store.dispatch(resetListAnsweredYears());
    store.dispatch(resetEnergyUsageType());
  }

  // 登録済みの年度リストを取得する
  await store.dispatch(listAnsweredFiscalYear());
  const listYear = store.getState().report.listAnsweredYears;
  if (!listYear || listYear.years.length < 1)
    throw Error("Not found answered fiscal year");

  // 決算月の取得
  await store.dispatch(getCurrentUser());
  const closingMonth =
    store.getState().users.currentUser?.company.closing_month;
  if (!closingMonth) throw Error("Not found closing month");
  const months = createMonthsFromClosingMonth(closingMonth);

  // 拠点の取得
  await store.dispatch(getBranches());
  const { branches } = store.getState().branches;
  if (!branches.length) throw Error("get branches failed");

  /**
   * エネルギー種別を取得
   * データが登録されているエネルギー種別のみを取得する
   * NOTE: fetch後データを整形しているのでgetStateで取得
   */
  await store.dispatch(energyUsageType());
  const { type: listEnergyType } = store.getState().energyUsages;
  // NOTE: データ登録がない場合はエラーを返す
  if (listEnergyType === null || !listEnergyType.length)
    throw Error("get list energy type failed");

  /**
   * エネルギー種別をタブごとに分ける
   * NUMBER_OF_INDICATED_TABS の数だけ独立して表示する
   *
   * indications: 独立して表示するエネルギー種別
   * others: その他タブに含まれるエネルギー種別
   */
  const energyTypeTabs: EnergyTypeTabs = {
    indications: listEnergyType.slice(0, NUMBER_OF_INDICATED_TABS),
    others: listEnergyType.slice(NUMBER_OF_INDICATED_TABS),
  };

  const defaultEnergyMasterId = Number(listEnergyType[0].energy_type_master_id);
  const defaultFiscalYear = Number(listYear.years[0]);

  const {
    energy_type_master_id,
    branch_id: branchIdParam,
    fiscal_year: fiscalYearParam,
  } = qs.parse(url.search);

  const energyTypeMasterId = Number(
    energy_type_master_id ?? defaultEnergyMasterId
  );
  const currentYear = Number(fiscalYearParam ?? defaultFiscalYear);
  // NOTE: 全拠点の id は 0 とする
  const branchId = Number(branchIdParam ?? 0);

  /** クエリパラメータからタブの種類を設定 */
  let tabType: EnergyTypeTabsProps = "indications";
  const isOthersTab = energyTypeTabs.others.some(
    item => item.energy_type_master_id === energyTypeMasterId
  );
  if (isOthersTab) tabType = "others";

  /** クエリパラメータからタブの情報を設定 */
  const tabInfo: EnergyUsageType =
    listEnergyType.find(
      item => item.energy_type_master_id === energyTypeMasterId
    ) ?? listEnergyType[0];

  // エネルギー使用量を取得する
  await store.dispatch(
    energyUsageByMonth({
      energy_type_master_id: energyTypeMasterId,
      fiscal_year: currentYear,
      branch_id: branchId !== 0 ? branchId : undefined,
    })
  );

  const branchName =
    branches.find(branch => branch.id === branchId)?.name ??
    ALL_BRANCH_SELECT_LABEL;

  return {
    energyTypeTabs,
    years: listYear.years,
    tabType,
    currentYear,
    branchId,
    branchName,
    closingMonth,
    months,
    tabInfo,
  };
};

const energyUsagePageLoader: LoaderFunction = async params => {
  const loaderPromise = energyUsageLoader(params);

  const loaderData: EnergyUsageLoaderReturn = {
    deferData: loaderPromise,
  };
  return defer(loaderData);
};

export default energyUsagePageLoader;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { saveLocalTitlePage } from "@app/helpers/util.helper";
import { createApiAsyncThunk } from "@app/redux/api.thunk";
import {
  MAIN_COLOR,
  SECOND_COLOR,
  THIRD_COLOR,
} from "@app/styles/common_colors";

import { getTenantApi } from "../api/tenants.api";
import { TENANTS_FEATURE_KEY } from "../constants/tenants.keys";
import {
  clearCurrentTenantCookie,
  GetTenantResponse,
  saveCurrentTenantCookie,
  TenantState,
} from "../tenants";

const initialState: TenantState = {
  tenant: null,
};

export const getTenant = createApiAsyncThunk(
  `${TENANTS_FEATURE_KEY}/getTenant`,
  (tenant_name_eng: string) => {
    return getTenantApi(tenant_name_eng);
  }
);

export const tenantSlice = createSlice({
  name: TENANTS_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Get
    builder.addCase(
      getTenant.fulfilled,
      (state, action: PayloadAction<GetTenantResponse>) => {
        state.tenant = action.payload;
        const { main_color_code, second_color_code, third_color_code } =
          action.payload;
        if (!main_color_code) {
          state.tenant.main_color_code = MAIN_COLOR;
        }

        if (!second_color_code) {
          state.tenant.second_color_code = SECOND_COLOR;
        }

        if (!third_color_code) {
          state.tenant.third_color_code = THIRD_COLOR;
        }
        saveCurrentTenantCookie(action.payload);
        saveLocalTitlePage(action.payload.title_page);
      }
    );
    builder.addCase(getTenant.rejected, state => {
      state.tenant = null;
      clearCurrentTenantCookie();
    });
  },
});

export { TENANTS_FEATURE_KEY, initialState as tenantInitialState };
export const tenantReducer = tenantSlice.reducer;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { convertUnit } from "@app/helpers/util.helper";
import { RootState } from "@app/redux";
import { createApiAsyncThunk, errorHandler } from "@app/redux/api.thunk";

import { getTenantEng } from "../../tenants/helpers/tenants.helpers";
import {
  energyUsageByMonthApi,
  energyUsageTypeApi,
} from "../api/energy_usages.api";
import { ENERGY_USAGES_KEY } from "../constants/energy_usages.keys";
import {
  EnergyUsageType,
  EnergyUsageTypeResponse,
  EnergyUsageByMonthResponse,
  DataTypesEnergyUsage,
  DataTypesEnergyUsageTotal,
  EnergyUsageByMonthRequestParams,
} from "../energy_usages";

interface EnergyUsageStateType {
  type: EnergyUsageType[] | null;
  data: DataTypesEnergyUsage[];
  total: DataTypesEnergyUsageTotal;
  isLoading: boolean;
}

const initialState: EnergyUsageStateType = {
  type: null,
  data: [],
  total: {
    usageAmount: null,
    costAmount: null,
  },
  isLoading: true,
};

const tenant_name_eng = getTenantEng();

export const energyUsageByMonth = createApiAsyncThunk(
  `${ENERGY_USAGES_KEY}/energyUsageByMonth`,
  async (params: EnergyUsageByMonthRequestParams) => {
    return energyUsageByMonthApi(tenant_name_eng, params);
  }
);

export const energyUsageType = createAsyncThunk<
  EnergyUsageTypeResponse,
  void,
  { state: RootState }
>(
  `${ENERGY_USAGES_KEY}/energyUsageType`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await energyUsageTypeApi(tenant_name_eng);
      return response.responseBody.data;
    } catch (err) {
      return rejectWithValue(errorHandler(err));
    }
  },
  {
    condition: (_, { getState }) => {
      const { energyUsages } = getState();
      const data = energyUsages.type;
      return data === null || data.length === 0;
    },
  }
);

export const energyUsageSlice = createSlice({
  name: ENERGY_USAGES_KEY,
  initialState,
  reducers: {
    resetEnergyUsageType: state => {
      state.type = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      energyUsageType.fulfilled,
      (state, action: PayloadAction<EnergyUsageTypeResponse>) => {
        const { data } = action.payload;
        // 単位変換
        data.map(item => {
          item.unit = convertUnit(item.unit);
          return item;
        });
        state.type = data;
      }
    );
    builder.addCase(energyUsageByMonth.pending, state => {
      state.data = [];
      state.total.usageAmount = null;
      state.total.costAmount = null;
      state.isLoading = true;
    });
    builder.addCase(
      energyUsageByMonth.fulfilled,
      (state, action: PayloadAction<EnergyUsageByMonthResponse>) => {
        const { data, total } = action.payload;
        state.total.usageAmount = total?.usage_amount ?? null;
        state.total.costAmount = total?.total_amount ?? null;
        state.data = data.reduce((result, item) => {
          const record: DataTypesEnergyUsage = {
            month: item.month,
            usageAmount: item.usage_amount?.value ?? null,
            usagePrevAmount: item.usage_amount?.prev_value ?? null,
            usageGrowthRate: item.usage_amount?.growth_rate ?? null,
            costAmount: item.total_amount?.value ?? null,
            costPrevAmount: item.total_amount?.prev_value ?? null,
            costGrowthRate: item.total_amount?.growth_rate ?? null,
          };
          result.push(record);
          return result;
        }, [] as DataTypesEnergyUsage[]);
        state.isLoading = false;
      }
    );
  },
});

export { ENERGY_USAGES_KEY, initialState as energyUsageInitialState };
export const { resetEnergyUsageType } = energyUsageSlice.actions;
export const energyUsageReducer = energyUsageSlice.reducer;

import { EmissionPaths } from "@app/features/emissions/emissions";
import { EnergyUsagePaths } from "@app/features/energy_usages/energy_usages";
import { ReportPaths } from "@app/features/reports/reports";
import { RoadmapPaths } from "@app/features/roadmap/roadmap";
import { SidebarInfo } from "@app/types/sidebar.types";

export const DURATION_NOTIFICATION = 5;

export const MENU_SIDEBAR: SidebarInfo[] = [
  {
    key: "1",
    label: "年度データ報告",
    icon_class: "icon-report",
    path: ReportPaths.REPORT(),
  },
  {
    key: "2",
    label: "CO2排出量",
    icon_class: "icon-search",
    path: EmissionPaths.EMISSION(),
  },
  {
    key: "3",
    label: "エネルギー使用量",
    icon_class: "icon-energy",
    path: EnergyUsagePaths.ENERGY_USAGE(),
  },
  {
    key: "roadmap",
    label: "削減ロードマップ",
    icon_class: "icon-flag",
    path: RoadmapPaths.ROADMAP(),
  },
];

export const ALL_BRANCH_SELECT_LABEL = "全拠点";
export const ALL_BRANCH_ITEM_VALUE = 0;

export const OUTPUTFILE_TIMESTAMP_FORMAT = "YYYYMMDDHHmmss";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosPromise, isAxiosError } from "axios";

export const errorHandler = (err: unknown) => {
  if (!isAxiosError(err)) {
    return "network.unknown";
  }
  const { request, response } = err;
  if (response) {
    // process error to based on type
    const { responseBody } = response.data;
    return responseBody.message.errors;
  }
  if (request) {
    // request sent but no response received
    return "network.noInternet";
  }
  // Something happened in setting up the request that triggered an Error
  return "network.unknown";
};

// NOTE: 常にAPIを呼び出す場合にのみ使用する
export const createApiAsyncThunk = <Result, ThunkArg = void>(
  key: string,
  action: (arg: ThunkArg) => AxiosPromise<Result>
) => {
  return createAsyncThunk<Result, ThunkArg>(
    key,
    async (arg, { rejectWithValue }) => {
      try {
        let result = await action(arg);
        let retry = false;
        if (!retry && !result) {
          // NOTE: もう !result は発生しないはず
          // 様子見のため残しておく
          // 再発した場合 responseInterceptor を要見直し
          retry = true;
          result = await action(arg);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return result.responseBody.data;
      } catch (error) {
        return rejectWithValue(errorHandler(error));
      }
    }
  );
};

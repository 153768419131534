// import { unwrapResult } from "@reduxjs/toolkit";
import { unwrapResult } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom";

import type { TenantModel } from "@app/features/tenants/tenants";
import { setLocalTitlePage } from "@app/helpers/util.helper";
import { getTenant } from "@app/redux";
import store from "@app/redux/store";

export interface RootLoader {
  tenant: TenantModel;
}

export const rootLoader: LoaderFunction = async ({
  params,
}: LoaderFunctionArgs): Promise<RootLoader> => {
  // handle tenant
  const { tenant_name_eng } = params;
  if (!tenant_name_eng) {
    throw Error();
  }
  setLocalTitlePage();

  let { tenant } = store.getState().tenants;
  if (!tenant || tenant_name_eng !== tenant.name_eng) {
    tenant = await store
      .dispatch(getTenant(tenant_name_eng))
      .then(unwrapResult);
    if (isEmpty(tenant)) {
      throw Error();
    }
  }
  return { tenant };
};

import { lazy, Suspense } from "react";

import { RouteObject } from "react-router-dom";

import confirmResetPasswordLoader from "./loader/confirm_reset_pass.loader";
import { loginLoader } from "./loader/login.loader";
import verifyTokenLoader from "./loader/verify_token.loader";

const ChartScreen = lazy(() => import("../screens/ChartScreen/ChartScreen"));
const ForgotPasswordScreen = lazy(
  () => import("../screens/ForgotPasswordScreen/ForgotPasswordScreen")
);
const LoginScreen = lazy(() => import("../screens/LoginScreen/LoginScreen"));

const ResetPasswordScreen = lazy(
  () => import("../screens/ResetPasswordScreen/ResetPasswordScreen")
);
const Template = lazy(() => import("../screens/TemplateScreen/Template"));
const AgreeTermsScreen = lazy(
  () => import("../screens/AgreeTermsScreen/AgreeTermsScreen")
);
const SetupPasswordScreen = lazy(
  () => import("../screens/SetupPasswordScreen/SetupPasswordScreen")
);

const AUTH_ROUTES: RouteObject[] = [
  {
    path: "login",
    loader: loginLoader,
    element: (
      <Suspense fallback="loading please wait...">
        <LoginScreen />
      </Suspense>
    ),
  },
  {
    path: "forgot-password",
    element: (
      <Suspense fallback="loading please wait...">
        <ForgotPasswordScreen />
      </Suspense>
    ),
  },
  {
    path: "reset-password",
    loader: confirmResetPasswordLoader,
    element: (
      <Suspense fallback="loading please wait...">
        <ResetPasswordScreen />
      </Suspense>
    ),
  },
  {
    path: "agree",
    element: (
      <Suspense fallback="loading please wait...">
        <AgreeTermsScreen />
      </Suspense>
    ),
  },
  {
    path: "setup-password",
    loader: verifyTokenLoader,
    element: (
      <Suspense fallback="loading please wait...">
        <SetupPasswordScreen />
      </Suspense>
    ),
  },
  {
    path: "template",
    element: (
      <Suspense fallback="Loading">
        <Template />
      </Suspense>
    ),
  },
  {
    path: "chart",
    element: (
      <Suspense fallback="loading please wait...">
        <ChartScreen />
      </Suspense>
    ),
  },
];
export default AUTH_ROUTES;

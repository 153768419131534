import React from "react";

import { Button } from "antd";

const ReloadButton: React.FC = () => (
  <Button
    type="link"
    size="small"
    className="btn-reload"
    onClick={() => window.location.reload()}
  >
    リロード
  </Button>
);

export default ReloadButton;

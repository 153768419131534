import { api } from "@app/api/api";
import { CustomAxiosResponse } from "@app/api/api.types";

import { TenantsEndpoints } from "../constants/tenants.endpoints";
import { GetTenantResponse } from "../tenants";

export const getTenantApi = (
  tenant_name_eng: string
): Promise<CustomAxiosResponse<GetTenantResponse>> => {
  return api.get<GetTenantResponse, CustomAxiosResponse>(
    TenantsEndpoints.GET(tenant_name_eng)
  );
};

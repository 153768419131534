import { getTenantEng } from "../../tenants/helpers/tenants.helpers";

const tenant_name_eng = getTenantEng();

export enum EmissionRoutePaths {
  EMISSION = "/:tenant_name_eng/emissions",
}

export const EmissionPaths = {
  EMISSION: () => `/${tenant_name_eng}/emissions`,
};

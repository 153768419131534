export const INPUT_TYPE = {
  TEXT_BOX: "text_box",
  TEXT_BOX_SHORT: "text_box_short",
  TEXT_AREA: "text_area",
  RADIO_BUTTON: "radio_button",
} as const;

export const INPUT_STATUS_TYPE = {
  NOT_ENTERED: 0,
  PROCESSING: 1,
  COMPLETED: 2,
} as const;

export const QUESTION_GROUPS_TYPE = {
  COMMON: "common",
  TENANT: "tenant",
  INVOICE: "invoice",
} as const;

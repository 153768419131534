import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { logoutServer, useAppDispatch } from "@app/redux";

import { logoutLocal } from "../helpers/auth.helpers";

/**
 * logout hook
 *
 * 一緒にroute変更も行う
 * @returns function
 */
export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return useCallback(async () => {
    await dispatch(logoutServer());
    logoutLocal(navigate);
  }, [dispatch, navigate]);
};

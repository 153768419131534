import qs from "query-string";
import { defer, LoaderFunction, LoaderFunctionArgs } from "react-router-dom";

import {
  ALL_BRANCH_ITEM_VALUE,
  ALL_BRANCH_SELECT_LABEL,
} from "@app/constants/constants";
import type { Branch } from "@app/features/branches/branches";
import type { ListYears } from "@app/features/reports/reports";
import {
  getBranches,
  getCo2Emissions,
  getCo2EmissionsByBranch,
  getCo2EmissionsByMonth,
  getCo2EmissionsByScope,
  listAnsweredFiscalYear,
  resetListAnsweredYears,
} from "@app/redux";
import store from "@app/redux/store";

import {
  type EmissionLoaderReturn,
  type EmissionLoaderType,
  type EmissionSearchCondition,
  type PeriodTabType,
  PERIOD_TAB_KEY,
  YEARLY_PERIOD_LENGTH,
} from "../../emissions";

/**
 * searchParamsをパースしてEmissionSearchParamを返す
 * @param request
 * @returns EmissionSearchParam
 */
const parseSearchParams = (
  request: LoaderFunctionArgs["request"],
  listYear: ListYears,
  branches: Branch[]
): EmissionSearchCondition => {
  const url = new URL(request.url);
  const {
    from: fromYearParam,
    to: toYearParam,
    fiscal_year: fiscalYearParam,
    branch_id: branchIdParam,
    period: periodParam,
  } = qs.parse(url.search, { parseNumbers: true });

  // パラメータの検証
  const period: PeriodTabType =
    periodParam === PERIOD_TAB_KEY.years ? periodParam : PERIOD_TAB_KEY.month;

  const defaultFiscalYear = Number(listYear.years[0]); // 最新の年度
  const parseYear = (param: unknown, defYear: number = defaultFiscalYear) => {
    if (
      param &&
      listYear.years.some(year => year.toString() === param.toString())
    ) {
      return Number(param);
    }
    return defYear;
  };

  const fiscalYear = parseYear(fiscalYearParam);
  const to = parseYear(toYearParam);
  const from =
    !fromYearParam || fromYearParam.toString() > to.toString()
      ? to - (YEARLY_PERIOD_LENGTH - 1)
      : parseYear(fromYearParam, to - (YEARLY_PERIOD_LENGTH - 1));

  const branch: Branch = branches.find(b => b.id === branchIdParam) ?? {
    id: ALL_BRANCH_ITEM_VALUE,
    name: ALL_BRANCH_SELECT_LABEL,
  };

  return {
    period,
    fiscalYear,
    from,
    to,
    branchId: branch.id,
    branchName: branch.name,
  };
};

const emissionTransitionLoader = async (
  condition: EmissionSearchCondition
): Promise<EmissionLoaderType> => {
  const { period, branchId, fiscalYear, from, to } = condition;

  // TODO: api側でfiscal_year/from & to の defaultを決めれば、allSettledで呼び出し可能
  // レスポンス次第で要検討
  if (period === PERIOD_TAB_KEY.month) {
    await store.dispatch(
      getCo2EmissionsByMonth({
        fiscal_year: fiscalYear,
        branch_id: branchId !== ALL_BRANCH_ITEM_VALUE ? branchId : undefined,
      })
    );
  } else if (period === PERIOD_TAB_KEY.years) {
    await store.dispatch(
      getCo2Emissions({
        from,
        to,
        branch_id: branchId !== ALL_BRANCH_ITEM_VALUE ? branchId : undefined,
      })
    );
  }

  return condition;
};

/**
 * page loader
 * @param params
 * @returns
 */
const emissionPageLoader: LoaderFunction = async params => {
  const { tenant_name_eng } = params.params;
  // テナント名が無い場合はエラー
  if (!tenant_name_eng) throw Error();

  const url = new URL(params.request.url);
  if (!url.search) {
    // パラメータなしの場合は再取得するようにリセット
    store.dispatch(resetListAnsweredYears());
  }
  // 年度と拠点一覧を取得
  await Promise.allSettled([
    store.dispatch(listAnsweredFiscalYear()),
    store.dispatch(getBranches()),
  ]);

  const listYear = store.getState().report.listAnsweredYears;
  if (!listYear || listYear.years.length < 1)
    throw Error("Not found answered fiscal year");
  const { branches } = store.getState().branches;
  if (!branches.length) throw Error("get branches failed");

  // 検索条件の取得
  const condition = parseSearchParams(params.request, listYear, branches);

  // promise 作成
  // 推移データ
  const transitionPromise = emissionTransitionLoader(condition);

  // 拠点別割合
  const branchRatePromise =
    condition.period === PERIOD_TAB_KEY.month &&
    condition.branchId === ALL_BRANCH_ITEM_VALUE &&
    store
      .dispatch(
        getCo2EmissionsByBranch({
          fiscal_year: condition.fiscalYear,
        })
      )
      .unwrap()
      .then(res => res);

  const scopeRatePromise =
    condition.period === PERIOD_TAB_KEY.month &&
    store
      .dispatch(
        getCo2EmissionsByScope({
          fiscal_year: condition.fiscalYear,
          branch_id:
            condition.branchId !== ALL_BRANCH_ITEM_VALUE
              ? condition.branchId
              : undefined,
        })
      )
      .unwrap()
      .then(res => res);

  const loaderData: EmissionLoaderReturn = {
    transition: transitionPromise,
    branchRate: branchRatePromise || undefined,
    scopeRate: scopeRatePromise || undefined,
  };
  return defer(loaderData);
};

export default emissionPageLoader;

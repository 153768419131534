import { getTenantEng } from "../../tenants/helpers/tenants.helpers";

const tenant_name_eng = getTenantEng();
export enum ReportRoutePaths {
  REPORT = "/:tenant_name_eng/reports",
}

export const ReportPaths = {
  REPORT: () => `/${tenant_name_eng}/reports`,
};

export const PATH_PARAM_BRANCH_ID = "branch_id";

import qs from "query-string";
import { LoaderFunction } from "react-router-dom";

import { AnswerInvoiceGroup } from "@app/features/branches/branches";
import { getInvoiceAnswerList } from "@app/redux";
import store from "@app/redux/store";

import { createInvoiceGroup } from "../../reports";

export type AnnualUsagesLoader = {
  branchId: number;
  invoiceGroups: AnswerInvoiceGroup[];
};

/**
 * page loader
 * @param params
 * @returns
 */
const annualInvoiceUsagesLoader = async (
  fiscal_year: number,
  branch_id: string
): Promise<AnnualUsagesLoader> => {
  // promise 作成
  const { closing_month, invoices } = await store
    .dispatch(
      getInvoiceAnswerList({
        fiscal_year,
        branch_id,
      })
    )
    .unwrap();

  const invoiceGroups = createInvoiceGroup(
    invoices,
    Number(fiscal_year),
    closing_month
  );

  return {
    invoiceGroups,
    branchId: Number(branch_id),
  };
};

// NOTE: 今の所 reports 内での遷移のみを想定しているため、defer loadingは使用していない
const annualInvoiceUsagesPageLoader: LoaderFunction = async params => {
  const { branch_id } = params.params;
  if (!branch_id) throw Error();

  const url = new URL(params.request.url);
  const searchParams = qs.parse(url.search, { parseNumbers: true });
  const { "fiscal-year": fiscal_year } = searchParams;

  if (!fiscal_year || typeof fiscal_year !== "number") return null; // 親routerでvalidationされる

  const loadData = await annualInvoiceUsagesLoader(fiscal_year, branch_id);
  return loadData;
};

export default annualInvoiceUsagesPageLoader;

import { api } from "@app/api/api";
import { CustomAxiosResponse } from "@app/api/api.types";

import {
  EnergyUsageByMonthRequestParams,
  EnergyUsageByMonthResponse,
  EnergyUsageEndpoints,
  EnergyUsageTypeResponse,
} from "../energy_usages";

export const energyUsageByMonthApi = (
  tenant_name_eng: string,
  params: EnergyUsageByMonthRequestParams
): Promise<CustomAxiosResponse<EnergyUsageByMonthResponse>> => {
  return api.get<EnergyUsageByMonthResponse, CustomAxiosResponse>(
    EnergyUsageEndpoints.ENERGY_USAGE_BY_MONTH(tenant_name_eng),
    {
      params,
    }
  );
};

export const energyUsageTypeApi = (
  tenant_name_eng: string
): Promise<CustomAxiosResponse<EnergyUsageTypeResponse>> => {
  return api.get<EnergyUsageTypeResponse, CustomAxiosResponse>(
    EnergyUsageEndpoints.ENERGY_USAGE_TYPE(tenant_name_eng)
  );
};

import React from "react";

import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";

import ReloadButton from "@app/components/atoms/ReloadButton/ReloadButton";
import { DURATION_NOTIFICATION } from "@app/constants/constants";

interface NotificationProps {
  type: "success" | "info" | "warning" | "error";
  message: React.ReactNode;
  placement?: NotificationPlacement;
  description?: React.ReactNode;
  reloadBtn?: boolean;
  onClose?: () => void;
}

export const openNotification = ({
  type,
  message,
  placement = "top",
  description = "",
  reloadBtn = false,
  onClose,
}: NotificationProps) => {
  notification.config({ maxCount: 1 });
  const btn = reloadBtn ? React.createElement(ReloadButton) : null;
  return notification[type]({
    message,
    description,
    placement,
    duration: DURATION_NOTIFICATION,
    btn,
    onClose,
  });
};

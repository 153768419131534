import { getTenantEng } from "../../tenants/helpers/tenants.helpers";

const tenant_name_eng = getTenantEng();

export enum EnergyRoutePaths {
  ENERGY_USAGE = "/:tenant_name_eng/energy-usages",
}

export const EnergyUsagePaths = {
  ENERGY_USAGE: () => `/${tenant_name_eng}/energy-usages`,
};

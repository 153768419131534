import { Suspense, lazy } from "react";

import Title from "antd/es/typography/Title";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";

import roadmapLoader from "./loader/roadmap.loader";

const RoadmapScreen = lazy(
  () => import("../screens/RoadmapScreen/RoadmapScreen")
);

const ROADMAP_ROUTES = [
  {
    path: "roadmap",
    loader: roadmapLoader,
    element: (
      <Suspense
        fallback={<LoadingSpinner isFullscreen text="Loading please wait..." />}
      >
        <RoadmapScreen />
      </Suspense>
    ),
    errorElement: (
      <Title level={4} className="font-weight-medium text-center mt-8">
        現在、このページのデータがありません
      </Title>
    ),
  },
];

export default ROADMAP_ROUTES;

import { defer, LoaderFunction, redirect } from "react-router-dom";

import { QuestionAnswerDetail } from "@app/features/question_answers/question_answers";
import { validateQueryParams } from "@app/features/reports/reports";
import {
  getBranches,
  getQuestionAnswerDetail,
  listFiscalYear,
} from "@app/redux";
import store from "@app/redux/store";

export interface AnnualReportLoader {
  fiscal_year: number;
  closingMonth: number;
  step: number;
  questionAnswerDetail: QuestionAnswerDetail;
}

export type AnnualReportLoaderReturn = {
  loader: Promise<AnnualReportLoader>;
};

type ReportSearchCondition = {
  fiscal_year: number;
  step: number | null;
};

const annualReportLoader = async ({
  fiscal_year,
  step: paramStep,
}: ReportSearchCondition): Promise<AnnualReportLoader> => {
  // questionAnswerDetail取得
  await store.dispatch(
    getQuestionAnswerDetail({ fiscal_year, step: paramStep })
  );

  const { questionAnswerDetail } = store.getState().questionAnswer;
  if (questionAnswerDetail === null)
    throw Error("get question answer detail failed");
  const {
    step,
    company: { closing_month },
  } = questionAnswerDetail;

  const data = {
    fiscal_year,
    closingMonth: closing_month,
    step,
    questionAnswerDetail,
  };
  return data;
};

const annualReportPageLoader: LoaderFunction = async params => {
  const { tenant_name_eng, branch_id: branchIdParam } = params.params;
  if (!tenant_name_eng) throw Error();

  const { request } = params;

  // 年度と拠点一覧を取得
  await Promise.allSettled([
    store.dispatch(listFiscalYear()),
    store.dispatch(getBranches()),
  ]);

  // 年度リスト必須
  const listYear = store.getState().report.listYears;
  if (!listYear || listYear.years.length < 1)
    throw Error("Not found fiscal year");
  const { branches } = store.getState().branches;

  // URL search params
  const defaultFiscalYear = Number(listYear.years[0]);
  const defaultURL = `/${tenant_name_eng}/reports?fiscal-year=${defaultFiscalYear}`;
  // URLからparamを取得
  const url = new URL(request.url);
  const fiscalYearParam = url.searchParams.get("fiscal-year");
  const stepParam = url.searchParams.get("step");
  // paramのバリデーション
  if (
    !validateQueryParams({
      step: stepParam,
      fiscalYear: fiscalYearParam,
      branchId: branchIdParam,
      listYear,
      branches,
    })
  )
    return redirect(defaultURL);

  const condition: ReportSearchCondition = {
    fiscal_year: Number(fiscalYearParam),
    step: stepParam !== null ? Number(stepParam) : null,
  };

  // defer 処理
  // NOTE: この中でredirectしても効かないので、redirectが必要な処理はこの前で実施
  const promise = annualReportLoader(condition);

  const loaderData: AnnualReportLoaderReturn = {
    loader: promise,
  };

  return defer(loaderData);
};

export default annualReportPageLoader;

/* eslint-disable react-hooks/rules-of-hooks */
import { memo, useEffect, useLayoutEffect, useState } from "react";

import {
  Layout,
  Menu,
  Dropdown,
  Space,
  Image,
  Typography,
  ConfigProvider,
  MenuProps,
} from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content, Header } from "antd/lib/layout/layout";
import cx from "classnames";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import defaultLogo from "@app/assets/images/tenant-logo-default.svg";
import Favicon from "@app/components/atoms/Favicon/Favicon";
import { MENU_SIDEBAR } from "@app/constants/constants";
import { useLogout } from "@app/features/auth/auth";
// eslint-disable-next-line no-restricted-imports
import MenuContact from "@app/features/reports/components/Menucontact/MenuContact";
import { UsersPaths } from "@app/features/users/users";
import useWindowSize from "@app/hooks/useWindowSize";
import { type RootState, useAppSelector } from "@app/redux";
import themeConfig from "@app/styles/antd-theme";
import { SidebarInfo } from "@app/types/sidebar.types";

import styles from "./AuthenticatedLayout.module.scss";

const AuthenticatedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string>("");
  const [, setHistoryIndex] = useState(window.history.state?.idx);
  const [isNavigateAccountInfo, setIsNavigateAccountInfo] =
    useState<boolean>(false);
  const { Title } = Typography;

  const {
    users: { currentUser },
    tenants: { tenant },
  } = useAppSelector((state: RootState) => state);

  const logout = useLogout();
  const handleLogout = () => {
    logout();
  };

  const handleNavigateSideMenu = (itemInfo: SidebarInfo) => {
    navigate(itemInfo.path);
  };

  // 画面サイズにより左ナビの開閉を制御
  useLayoutEffect(() => {
    width >= 1440 ? setCollapsed(false) : setCollapsed(true);
  }, [width]);

  useEffect(() => {
    setIsNavigateAccountInfo(false);
    if (location.pathname === UsersPaths.ACCOUNT_INFORMATION()) {
      setIsNavigateAccountInfo(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const currentTab = MENU_SIDEBAR.find(item =>
      location.pathname.startsWith(item.path)
    );

    currentTab && setSelectedKey(currentTab.key);
    setHistoryIndex(window.history.state?.idx);

    return () => {
      setSelectedKey("");
    };
  }, [location]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  const MENU_DROPDOWN: MenuProps["items"] = [
    {
      disabled: true,
      label: (
        <>
          <span>{currentUser?.company.name}</span>
          <span className="secondary-title">{currentUser?.email}</span>
        </>
      ),
      key: "header-menu",
    },
    {
      type: "divider",
    },
    {
      label: <Link to={UsersPaths.ACCOUNT_COMPANIES()}>操作企業切替</Link>,
      key: "account_companies",
    },
    {
      label: <Link to={UsersPaths.ACCOUNT_INFORMATION()}>アカウント設定</Link>,
      key: "account_info",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div aria-hidden="true" onClick={handleLogout}>
          ログアウト
        </div>
      ),
      key: "logout",
    },
  ];

  return (
    <ConfigProvider
      theme={themeConfig(tenant?.main_color_code, tenant?.third_color_code)}
    >
      <Favicon href={tenant?.favicon_url} />
      <Layout className={cx(styles.root)}>
        <Header className="header">
          <div className="flex-space-between-center header-content">
            <div className="flex-align-center">
              <span
                aria-hidden
                onClick={() => setCollapsed(!collapsed)}
                className="icon-menu cursor-pointer mr-6 font-14"
                style={{ color: `#${tenant?.main_color_code}` }}
              />
              <div className="flex-direction-column">
                <Link to="./">
                  <Image
                    src={tenant?.logo_url}
                    height={36}
                    fallback={defaultLogo}
                    preview={false}
                    style={{ objectFit: "contain" }}
                  />
                </Link>
              </div>
            </div>
            <div className="flex-center">
              {tenant?.faq_url && (
                <a
                  href={tenant?.faq_url}
                  target="_blank"
                  rel="noreferrer"
                  className="d-flex"
                >
                  <span
                    className="icon-question mr-6 font-22"
                    style={{ color: `#${tenant?.main_color_code}` }}
                  />
                </a>
              )}
              <Dropdown
                placement="bottomRight"
                menu={{ items: MENU_DROPDOWN }}
                trigger={["click"]}
                getPopupContainer={triggerNode => triggerNode}
              >
                <Space className="text-dark cursor-pointer">
                  <Title
                    ellipsis={{
                      tooltip: `${currentUser?.name} (${currentUser?.company.name})`,
                      rows: 1,
                    }}
                  >
                    {currentUser?.name}
                  </Title>
                  <Title
                    ellipsis={{
                      tooltip: `${currentUser?.name} (${currentUser?.company.name})`,
                      rows: 1,
                    }}
                  >
                    ({currentUser?.company.name})
                  </Title>
                  <span
                    className="icon-arrow-down ml-1"
                    style={{ color: `#${tenant?.main_color_code}` }}
                  />
                </Space>
              </Dropdown>
            </div>
          </div>
        </Header>

        <Layout hasSider>
          <Sider
            className={cx(styles.sider)}
            collapsible
            defaultCollapsed
            collapsed={collapsed}
            trigger={null}
          >
            <Menu
              className={`${
                isNavigateAccountInfo && "navigate_account_info"
              } mt-6`}
              mode="inline"
              // defaultSelectedKeys={["1"]}
              selectedKeys={[selectedKey]}
              items={MENU_SIDEBAR.map(x => {
                return {
                  ...x,
                  icon: (
                    <span
                      className={x.icon_class}
                      style={{ color: `#${tenant?.second_color_code}` }}
                    />
                  ),
                  label: ((): string | React.ReactElement => {
                    if (x.key === "2") {
                      return (
                        <span>
                          CO<sub>2</sub>排出量
                        </span>
                      );
                    }
                    return x.label;
                  })(),
                  onClick: () => handleNavigateSideMenu(x),
                };
              })}
            />
            <div className="menu-contact">
              <MenuContact />
            </div>
          </Sider>

          <Content
            className={cx(styles.content, {
              [styles.contentCollapsed]: collapsed,
            })}
            style={{ backgroundColor: `#${tenant?.third_color_code}` }}
          >
            <div style={{ width: 1100, margin: "0 auto" }}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default memo(AuthenticatedLayout);

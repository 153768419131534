import { FC } from "react";

import Paragraph from "antd/es/typography/Paragraph";

import { formatIntOrFloat } from "@app/helpers/util.helper";

type Props = {
  value: number | null;
  unit: string | JSX.Element;
  align?: "left" | "right" | "center";
};

const RenderTableValue: FC<Props> = ({ value, unit, align = "right" }) => {
  const classArr = ["font-12", "mb-0"];
  if (align) classArr.push(`text-${align}`);
  if (value === null) return <>-</>;
  return (
    <Paragraph
      strong
      className={classArr.join(" ")}
      style={{ paddingRight: "10%" }}
    >
      {formatIntOrFloat(value)} {unit}
    </Paragraph>
  );
};

export default RenderTableValue;

import { memo, useCallback, useLayoutEffect } from "react";

import { Col, Row, Image, Layout, ConfigProvider } from "antd";
import { Outlet, useLoaderData, useLocation } from "react-router-dom";

import defaultLogo from "@app/assets/images/tenant-logo-default.svg";
import Favicon from "@app/components/atoms/Favicon/Favicon";
import { getCurrentTenant } from "@app/features/tenants/tenants";
import { getTenantUser } from "@app/helpers/route.helper";
import { RootLoader } from "@app/routes/loaders/root.loader";
import themeConfig from "@app/styles/antd-theme";

const { Content } = Layout;

const BlankLayout = memo(() => {
  const { tenant } = useLoaderData() as RootLoader;
  const location = useLocation();

  const fetchTenantUser = useCallback(async () => {
    const currentTenant = getCurrentTenant();
    const { name_eng } = tenant;
    if (currentTenant?.name_eng !== name_eng) {
      await getTenantUser(name_eng);
    }
  }, [tenant]);

  useLayoutEffect(() => {
    fetchTenantUser();
  }, [fetchTenantUser, location]);

  return (
    <ConfigProvider
      theme={themeConfig(tenant?.main_color_code, tenant?.third_color_code)}
    >
      <Favicon href={tenant?.favicon_url} />
      <Layout style={{ backgroundColor: `#${tenant?.third_color_code}` }}>
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col
            className="flex-center flex-direction-column"
            style={{ textAlign: "center" }}
          >
            <div>
              <Image
                src={tenant?.logo_url}
                height={75}
                fallback={defaultLogo}
                preview={false}
                style={{ objectFit: "contain" }}
              />
            </div>
            <Content className="mt-7">
              <Outlet />
            </Content>
          </Col>
        </Row>
      </Layout>
    </ConfigProvider>
  );
});

export default BlankLayout;

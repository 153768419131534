import { api } from "@app/api/api";
import { CustomAxiosResponse } from "@app/api/api.types";

import { RoadmapEndPoints } from "../roadmap";
import type { RoadmapResponse } from "../roadmap";

export const co2EmissionByRoadmapApi = (
  tenant_name_eng: string,
  params: void
) => {
  return api.get<RoadmapResponse, CustomAxiosResponse<RoadmapResponse>>(
    RoadmapEndPoints.CO2_EMISSION_ON_ROADMAP(tenant_name_eng),
    { params }
  );
};

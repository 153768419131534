export const BranchesEndpoints = {
  GET_BRANCHES: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/branches`,
  INVOICE_ANSWER_LIST: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/invoices/answer_summaries`,

  GET_INVOICES: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/invoices/list`,
  PUT_INVOICES: (tenant_name_eng: string) =>
    `api/v1/${tenant_name_eng}/invoices`,
};

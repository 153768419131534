import { FC } from "react";

import { Col, Image, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";

import downArrow from "@app/assets/images/arrow-down.svg";
import unchangeArrow from "@app/assets/images/arrow-unchange.svg";
import upArrow from "@app/assets/images/arrow-up.svg";
import { formatFloat } from "@app/helpers/util.helper";

interface Props {
  value: number | null;
}

const RenderTableGrowthRate: FC<Props> = ({ value }) => {
  if (value === null) return <>-</>;
  const rate = value > 0 ? formatFloat(value) : formatFloat(-value);
  let src: string;
  let fontColor: string;
  if (value === 0) {
    src = unchangeArrow;
    fontColor = "#0089BC";
  } else if (value > 0) {
    src = upArrow;
    fontColor = "#e70000";
  } else {
    src = downArrow;
    fontColor = "#02bfa6";
  }
  return (
    <Row align="middle" justify="end">
      <Col span={8}>
        <Row justify="end">
          <Col>
            <Image
              src={src}
              height={18}
              width={18}
              preview={false}
              style={{ position: "relative", top: "-1px" }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={16}>
        <Paragraph
          strong
          className="text-right font-12 mb-0"
          style={{ paddingRight: "32%", color: fontColor }}
        >
          {rate} %
        </Paragraph>
      </Col>
    </Row>
  );
};

export default RenderTableGrowthRate;

import { FormInstance, FormProps } from "antd";
import dayjs from "dayjs";
import Encoding from "encoding-japanese";
import { isEqual } from "lodash";

import { openNotification } from "@app/components/molecules/Notification/notification";

/**
 * Check if a string looks like an external URL
 */
export const isURL = (str: string) => {
  return /http|www/.test(str);
};

/**
 * A promise to delay an async function
 * @param ms how many milliseconds to wait
 */
export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getInitials = (name: string, maxChar: number) => {
  return name
    .split(/\s/)
    .map(word => word[0])
    .join("")
    .substr(0, maxChar)
    .toUpperCase();
};

/**
 * Scroll to top of screen smoothly,
 * or fallback to instant scroll to top
 */
export const scrollToTop = () => {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // fallback for older browsers
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }
};

export const checkValidForm = ({
  form,
  fieldsRequire,
  isSubmitting,
}: {
  form: FormInstance;
  fieldsRequire?: string[];
  isSubmitting?: boolean;
}) => {
  if (isSubmitting) return true;
  return (
    (fieldsRequire ? !form.isFieldsTouched(fieldsRequire, true) : false) ||
    !!form.getFieldsError().filter(({ errors }) => errors.length).length
  );
};

export const setFieldErrors = ({
  err,
  form,
  errorField,
  defaultMessageError,
}: {
  err: Record<string, string[]> | string;
  form: FormInstance;
  errorField?: string;
  defaultMessageError?: string;
}) => {
  if (typeof err === "string") {
    let errStr = err;
    // if (err.startsWith("messages.MsgErr")) {
    //   errStr = defaultMessageError ?? "";
    // }
    errStr = defaultMessageError ?? "";
    if (errorField) {
      form.setFields([
        {
          name: errorField,
          errors: [errStr],
        },
      ]);
    } else {
      openNotification({
        type: "error",
        message: errStr,
      });
    }
  } else {
    form.setFields(
      Object.keys(err).map(key => ({
        name: key,
        errors: err[key],
      }))
    );
  }
};

export const filedTouched = (form: FormInstance, paths: string[]) => {
  return paths.every(path => form.isFieldTouched(path));
};

export const getTimeFiscalYear = () => {
  const fiscal_year = new URLSearchParams(window.location.search).get(
    "fiscal-year"
  );
  return fiscal_year
    ? `${fiscal_year}年04月01日〜${Number(fiscal_year) + 1}年03月31日`
    : "";
};

export const mockDataApi = {
  answer: {
    company: {
      name: "abc",
    },
  },
};

export const actionCheckDataChange = (data: FormProps, originalData: any) => {
  const compareData = isEqual(data, originalData);
  return compareData;
};

/**
 * 年度のバリデーション
 * @param value
 * @returns
 */
export const isYear = (value: string) => {
  const regEx = /^([1-9][0-9][0-9][0-9])$/;
  return regEx.test(value);
};

export const isInt = (number: number) => {
  return number % 1 === 0;
};

export const formatNumber = (number: number) => {
  return number.toLocaleString();
};

export const formatFloat = (number: number, digit = 2) => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  });
};

export const formatIntOrFloat = (value: number, digit = 2) => {
  return isInt(value) ? formatNumber(value) : formatFloat(value, digit);
};

/**
 * antd InputNumberのformatter
 * 入力数値をカンマ付きに整形
 * @param value number | string | undefined
 * @returns string
 */
export const formatInputNumber = (
  value: number | string | undefined
): string => {
  if (!value) return "";
  const convertValue = String(value);
  if (convertValue.includes(".")) {
    const formatValue = convertValue.split(".");
    return `${formatValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${
      formatValue[1]
    }`;
  }
  return convertValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * antd InputNumberのparser
 * カンマを除去する
 * @param value string | undefined
 * @returns number | string
 */
export const parseInputNumber = (
  value: string | undefined
): number | string => {
  if (!value) return "";
  return Number(value.replace(/\$\s?|(,*)/g, ""));
};

export const setLocalTitlePage = () => {
  const titlePage = window.sessionStorage.getItem("PAGE_TITLE");
  if (titlePage !== null) {
    document.title = titlePage;
  } else {
    document.title = "排出量報告プラットフォーム";
  }
};

export const saveLocalTitlePage = (titlePage: string) => {
  document.title = titlePage;
  window.sessionStorage.setItem("PAGE_TITLE", titlePage);
};

/**
 * 単位を表示用に変換する
 * @param unit
 * @returns string
 */
export const convertUnit = (unit: string): string => {
  return unit?.replace("m3", "m³");
};

/**
 * 月日を表示用に変換する
 * @param month
 * @param year
 * @param closingMonth
 * @returns string
 */
export const formatMonthWithYear = (
  month: number,
  year: number,
  closingMonth: number
): string => {
  if (closingMonth === 12) return `${year}年${month}月`;
  return month <= closingMonth
    ? `${year + 1}年${month}月`
    : `${year}年${month}月`;
};

/**
 * 決算月をもとに月の配列を作成する
 * @param closingMonth
 * @returns number[]
 */
export const createMonthsFromClosingMonth = (
  closingMonth: number
): number[] => {
  const res = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  if (closingMonth === 12) return res;
  for (let i = 0; i < closingMonth; i += 1) {
    const shift = res.shift();
    res.push(shift as number);
  }
  return res;
};

/**
 * 年度の範囲文字列を作成する
 * @param year
 * @param closingMonth
 * @returns string
 */
export const createFiscalYearRangeString = (
  year: number,
  closingMonth: number
): string => {
  const FM = "YYYY年M月D日";
  const startDt = dayjs(
    new Date(year, closingMonth === 12 ? 0 : closingMonth, 1)
  );
  const endDt = startDt.add(11, "month").endOf("month");

  return `${startDt.format(FM)}〜${endDt.format(FM)}`;
};

/**
 * 西暦を和暦に変換する
 * @param year
 * @param month
 * @returns string 令和x年
 */
export const toJPCalenderYear = (year: number, month = 12): string => {
  const date = new Date(year, month - 1, 1);
  return new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
    year: "numeric",
    era: "short",
  }).format(date);
};

/**
 * 西暦を和暦に変換する2
 * @param year
 * @param month
 * @returns string 令和x
 */
export const toJPCalenderYearShort = (year: number, month = 12): string => {
  return toJPCalenderYear(year, month).replace("年", "");
};

/**
 * Shift-JISに変換する
 * @param utf8String string
 * @returns Uint8Array
 */
export const toShiftJIS = (utf8String: string): Uint8Array => {
  const unicodeList = [];
  for (let i = 0; i < utf8String.length; i += 1) {
    unicodeList.push(utf8String.charCodeAt(i));
  }
  const sjisArray = Encoding.convert(unicodeList, {
    to: "SJIS",
    from: "UNICODE",
  });
  return new Uint8Array(sjisArray);
};

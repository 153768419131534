export const EmissionEndPoints = {
  CO2_EMISSION: (tenant_name_eng: string) =>
    `/api/v1/${tenant_name_eng}/co2-emissions`,
  CO2_EMISSION_BY_MONTH: (tenant_name_eng: string) =>
    `/api/v1/${tenant_name_eng}/co2-emissions-by-month`,
  CO2_EMISSION_BY_BRANCH: (tenant_name_eng: string) =>
    `/api/v1/${tenant_name_eng}/co2-emissions-by-branch`,
  CO2_EMISSION_BY_SCOPE: (tenant_name_eng: string) =>
    `/api/v1/${tenant_name_eng}/co2-emissions-by-scope`,
};

import { Suspense, lazy } from "react";

import Title from "antd/es/typography/Title";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";

import energyUsagePageLoader from "./loader/energy_usage.loader";

const EnergyUsageScreen = lazy(
  () => import("../screens/EnergyUsageScreen/EnergyUsageScreen")
);

const ENERGY_USAGES_ROUTES = [
  {
    path: "energy-usages",
    loader: energyUsagePageLoader,
    element: (
      <Suspense
        fallback={<LoadingSpinner isFullscreen text="Loading please wait..." />}
      >
        <EnergyUsageScreen />
      </Suspense>
    ),
    errorElement: (
      <Title level={4} className="font-weight-medium text-center mt-8">
        現在、このページのデータがありません
      </Title>
    ),
  },
];

export default ENERGY_USAGES_ROUTES;

import { getTenantEng } from "@app/features/tenants/tenants";

const tenant_name_eng = getTenantEng();

export enum AuthRoutePaths {
  LOGIN = "/:tenant_name_eng/login",
  FORGOT_PASSWORD = "/:tenant_name_eng/forgot-password",
  RESET_PASSWORD = "/:tenant_name_eng/reset-password",
  SETUP_PASSWORD = "/:tenant_name_eng/setup-password",
  AGREE_TERMS = "/:tenant_name_eng/agree",
  TEMPLATE = "/template",
  CHART = "/chart",
}

export const AuthPaths = {
  LOGIN: () => `/${tenant_name_eng}/login`,
  FORGOT_PASSWORD: () => `/${tenant_name_eng}/forgot-password`,
  RESET_PASSWORD: () => `/${tenant_name_eng}/reset-password`,
  SETUP_PASSWORD: () => `/${tenant_name_eng}/setup-password`,
  TEMPLATE: () => "/template",
  CHART: () => "/chart",
};

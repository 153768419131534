import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { RootState, AppDispatch } from ".";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * typeをrequriedにして返却する
 * ログイン後の画面で利用すること
 */
export const useCurrentUser = () => {
  const currentUser = useAppSelector(state => state.users.currentUser);
  if (!currentUser) throw Error("Not found currentUser");
  return currentUser;
};

/* eslint-disable filenames/match-regex */

export const ENERGY_TYPE = {
  ELECTRIC: 1,
  GAS: 2,
  OTHER: 3,
};

export const ANSWER_STATUS = {
  IN_PROGRESS: 1,
  REPORTED: 2,
  APPROVED: 3,
} as const;

export const ANSWER_INPUT_STATUS = {
  NOT_ENTERD: 0,
  PROGRESS: 1,
  FINISHED: 2,
} as const;

export const TENANT_APPROVE_STATUS = {
  PROGRESS: 0,
  FINISHED: 1,
} as const;

export const ANSWER_STATUS_TEXT = {
  [ANSWER_STATUS.IN_PROGRESS]: "作業中",
  [ANSWER_STATUS.REPORTED]: "報告済み",
  [ANSWER_STATUS.APPROVED]: "承認済み（公開中）",
} as const;

type AnswerStatusText =
  (typeof ANSWER_STATUS_TEXT)[keyof typeof ANSWER_STATUS_TEXT];

const STATUS_COLORS: Record<AnswerStatusText, string> = {
  [ANSWER_STATUS_TEXT[ANSWER_STATUS.IN_PROGRESS]]: "#BDBDBD",
  [ANSWER_STATUS_TEXT[ANSWER_STATUS.REPORTED]]: "#0089BC",
  [ANSWER_STATUS_TEXT[ANSWER_STATUS.APPROVED]]: "#02BFA6",
};

export const statusColor = (status: string) =>
  STATUS_COLORS[status as AnswerStatusText];

export const STEPS_STATUS: Record<
  string,
  "process" | "wait" | "finish" | "error" | undefined
> = {
  PROCESS: "process",
  WAIT: "wait",
  FINISH: "finish",
  ERROR: "error",
};

export const STEP_TITLES = [
  {
    title: "基本情報",
    subTitle: <>基本情報を入力してください。</>,
  },
  {
    title: "データ入力",
    subTitle: <></>,
  },
  {
    title: "活動報告",
    subTitle: <></>,
  },
  {
    title: "確認・提出",
    subTitle: <>下記入力内容の確認と修正を行い、提出ボタンを押して下さい。</>,
  },
  {
    title: "完了",
    subTitle: (
      <>
        お疲れ様でした！
        <br />
        今年度の報告は完了いたしました。
      </>
    ),
  },
];
